import { faPause, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { PlayButton, useMediaState } from '@vidstack/react';

import { RMIconButton } from '@/components/RMIconButton/RMIconButton';

import { StyledStoryPlayerPlayButton } from './StoryPlayerPlayButton.styles';

export function StoryPlayerPlayButton() {
  const isPaused = useMediaState('paused');

  return (
    <StyledStoryPlayerPlayButton>
      <PlayButton onClick={(event) => event.stopPropagation()}>
        <RMIconButton
          icon={isPaused ? faPlay : faPause}
          tooltip={{ label: isPaused ? 'Play' : 'Pause', position: 'top' }}
          backgroundColor="dark"
          color="white"
          as="div"
          size="lg"
          iconSize="lg"
        />
      </PlayButton>
    </StyledStoryPlayerPlayButton>
  );
}
