import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Id } from 'react-toastify';
import { AclGroupRole } from '@remento/types/acl';
import { AssetProcessingStatus, AssetType } from '@remento/types/asset';
import { EntityType } from '@remento/types/entity';
import { PromptType } from '@remento/types/project';
import { ReactionSentiment } from '@remento/types/reaction';
import { StoryDataType } from '@remento/types/story';
import { getStoryDownloadName } from '@remento/utils/entity/story';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { removeToast, toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { setInputValue } from '@/modules/form/input';
import { useShowPaywall } from '@/modules/paywall';
import { getQuestionsPath } from '@/modules/routing';
import { StorySummaryForm } from '@/modules/stories/forms/story-summary.form';
import { useStoryVideoAlternative } from '@/modules/stories/hooks/useStoryVideo.ts';
import { useServices } from '@/Services.tsx';
import { hasRole, useCurrentUserAclRoles } from '@/services/api/acl';
import { useAssetQuery } from '@/services/api/asset';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { EntityMutation } from '@/services/api/cache';
import { usePersonQuery } from '@/services/api/person';
import { CreatePromptPayload, usePromptFirstQuestionQuery, usePromptQuery } from '@/services/api/project';
import { useRecordingQuery } from '@/services/api/recording';
import { useStoryQuery, useStoryShareLinkQuery } from '@/services/api/story';
import { captureException } from '@/utils/captureException';
import { openFilestackPicker } from '@/utils/filestack';
import { openShareSheet, showShareSheetToast } from '@/utils/share-sheet';
import { secureUuid } from '@/utils/uuid';

import { StoryActions } from '../components/StoryActions/StoryActions';
import { setActiveStoryId, StoriesManager, useActiveStoryId, useStoriesIds } from '../states/stories.manager';
import { setStoryState, StoryManager, useStoryState } from '../states/story.manager';

export interface StoryActionsContainerProps {
  form: StorySummaryForm;
  storiesManager: StoriesManager;
  storyManager: StoryManager;
  onEditSummary?: () => void;
  onEditCancel?: () => void;
  onOpenRegenerate?: () => void;
  onSave?: () => void;
}

const EDIT_ROLES = [AclGroupRole.OWNER, AclGroupRole.ADMIN, AclGroupRole.COLLABORATOR];

export function StoryActionsContainer({
  form,
  storiesManager,
  storyManager,
  onEditSummary,
  onEditCancel,
  onSave,
  onOpenRegenerate,
}: StoryActionsContainerProps) {
  const mobile = useIsMobileViewport();
  const {
    assetService,
    projectCacheService,
    storyService,
    storyCacheService,
    storyViewerAnalyticsService,
    projectService,
    entityCacheManagerService,
  } = useServices();
  const navigate = useNavigate();
  const storyId = useActiveStoryId(storiesManager);
  const storiesIds = useStoriesIds(storiesManager);
  const storyState = useStoryState(storyManager);
  const storiesType = storiesManager.type;

  const storyQuery = useStoryQuery(storyId);
  const story = storyQuery?.data;
  const promptQuery = usePromptQuery(story?.promptId ?? null);
  const prompt = promptQuery?.data;
  const promptQuestionQuery = usePromptFirstQuestionQuery(story?.promptId ?? null);
  const promptQuestion = promptQuestionQuery?.data;
  const userStoryRoles = useCurrentUserAclRoles(story?.acl ?? null);

  const recordingAssetQuery = useAssetQuery(story?.recordingsIds[0]);
  const recordingAsset = recordingAssetQuery.data;
  const recordingAlternative = useStoryVideoAlternative(storyId);

  const recordingQuery = useRecordingQuery(story?.recordingsIds[0]);
  const user = useUser();
  const personQuery = usePersonQuery(user?.personId);
  const userHasRecorded = personQuery.data?.refIds.includes(recordingQuery.data?.personId ?? '');

  // Paywall
  const showPaywall = useShowPaywall(storyQuery.data?.projectId);

  // Can't react to your own recording
  const canReact = !userHasRecorded;
  const canEdit = hasRole(EDIT_ROLES, userStoryRoles ?? []);

  // share links (for mobile)
  const storyShareLinkQuery = useStoryShareLinkQuery(storyId);
  const shareLink = storyShareLinkQuery.data ?? '';

  const handleShare = useCallback(async () => {
    if (mobile && storiesType !== 'story') {
      const shareResult = await openShareSheet({ url: shareLink });
      showShareSheetToast(shareResult);
      storyViewerAnalyticsService.onStoryShared(
        storiesManager.type,
        shareResult == 'copied-to-clipboard' ? 'clipboard' : 'share-sheet',
      );
      return;
    }

    setStoryState(storyManager, { type: 'sharing' });
  }, [mobile, shareLink, storiesManager.type, storiesType, storyManager, storyViewerAnalyticsService]);

  const handleDownloadStory = async () => {
    if (recordingAsset == null || recordingAlternative == null) {
      return null;
    }

    storyViewerAnalyticsService.onStoriesActionPerformed('download');

    if (recordingAsset?.processingStatus !== AssetProcessingStatus.PROCESSED) {
      toast('The recording is processing, this may take a minute');
      return;
    }

    let toastId: Id = '';
    let downloadCancelled = false;
    const toasterTimeout = setTimeout(() => {
      toastId = toast('Exporting in progress', 'root-toast', 'default', {
        isLoading: true,
        onLoadingCancel: () => {
          if (toastId) {
            removeToast(toastId);
          }
          downloadCancelled = true;
        },
      });
    }, 2000);

    const ext = recordingAsset.type === AssetType.VIDEO_RECORDING ? '.mp4' : '.mp3';
    const filename = getStoryDownloadName(storyQuery.data) + ext;
    const downloadUrl = await assetService.getAlternativeDownloadVideoUrl(recordingAlternative.id, filename);

    clearTimeout(toasterTimeout);

    if (downloadCancelled) return;

    if (toastId) removeToast(toastId);

    window.location.assign(downloadUrl);
    storyViewerAnalyticsService.onStoryDownloaded('original');
  };

  const handleRemoveStory = useCallback(async () => {
    if (story == null) return;

    const deleteMutation = storyService.createDeleteStoryMutation(story);
    await storyCacheService.deleteStory(story.id, deleteMutation);

    setActiveStoryId(storiesManager, null);
    setStoryState(storyManager, { type: 'view', controls: 'visible', assistant: 'hidden' });
  }, [story, storyService, storyCacheService, storiesManager, storyManager]);

  const handleReRecordStory = useCallback(async () => {
    if (!prompt || !story || !promptQuestion) return null;

    const newPromptPayload: CreatePromptPayload =
      prompt?.type === PromptType.PHOTO
        ? {
            id: secureUuid(),
            type: PromptType.PHOTO,
            status: null,
            imagesIds: prompt.imagesIds,
            question: promptQuestion.text ?? '',
          }
        : {
            id: secureUuid(),
            type: PromptType.TEXT,
            status: null,
            question: promptQuestion.text ?? '',
            template: prompt.template,
          };

    const [newPrompt] = await projectCacheService.createPrompts(prompt.projectId, [newPromptPayload]);

    const cachedPrompt = await projectCacheService.getPrompt(newPrompt.id);
    if (!cachedPrompt) {
      throw new Error('Missing cached prompt with ID ' + newPrompt.id);
    }

    const mutations = projectService.createSetSentMutation(cachedPrompt);
    await entityCacheManagerService.mutate(mutations);

    navigate(getQuestionsPath(prompt.projectId));
  }, [prompt, story, promptQuestion, projectCacheService, projectService, entityCacheManagerService, navigate]);

  const handleAskRemove = useCallback(async () => {
    if (showPaywall()) {
      return;
    }

    setStoryState(storyManager, { type: 'removing' });
    storyViewerAnalyticsService.onStoriesActionPerformed('remove-story');
  }, [showPaywall, storyManager, storyViewerAnalyticsService]);

  const handleEditReaction = useCallback(
    (initialSentiment?: ReactionSentiment) => {
      setStoryState(storyManager, { type: 'reacting', initialSentiment });
    },
    [storyManager],
  );

  const handleCancelRemove = useCallback(() => {
    setStoryState(storyManager, { type: 'view', controls: 'visible', assistant: 'hidden' });
  }, [storyManager]);

  const handleAskReRecordStory = useCallback(async () => {
    if (showPaywall()) {
      return;
    }

    setStoryState(storyManager, { type: 're-recording' });
    storyViewerAnalyticsService.onStoriesActionPerformed('re-record');
  }, [showPaywall, storyManager, storyViewerAnalyticsService]);

  const handleCancelReRecord = useCallback(() => {
    setStoryState(storyManager, { type: 'view', controls: 'visible', assistant: 'hidden' });
  }, [storyManager]);

  const handleUpdatePhoto = useCallback(async () => {
    if (story == null) {
      return;
    }

    if (showPaywall()) {
      return;
    }

    try {
      await openFilestackPicker({
        accept: ['image/png', 'image/jpeg', 'image/webp'],
        maxSize: 1024 * 1024 * 20,
        onUploadDone: async ({ filesUploaded }) => {
          if (!filesUploaded[0]) {
            return;
          }

          try {
            const newAsset = await assetService.createFilestackAsset({
              entity: { id: story.id, type: EntityType.STORY },
              type: AssetType.IMAGE,
              handle: filesUploaded[0].handle,
            });

            const mutations: EntityMutation[] = [];
            if (story.imageAssetIds.length > 0) {
              mutations.push(...storyService.createDeleteStoryPhotoMutation(story, story.imageAssetIds[0]));
            }

            mutations.push(...storyService.createAddStoryPhotoMutation(story, newAsset.id));

            await entityCacheManagerService.mutate(mutations);
          } catch (error) {
            captureException(error, true);
            toast('Failed to update story photo', 'root-toast', 'error');
          }
        },
      });
    } catch (error) {
      captureException(error, true);
    }
  }, [assetService, entityCacheManagerService, showPaywall, story, storyService]);

  const handleAskRemovePhoto = useCallback(async () => {
    if (showPaywall()) {
      return;
    }

    setStoryState(storyManager, { type: 'photo-removing' });
  }, [showPaywall, storyManager]);

  const handleRemovePhoto = useCallback(async () => {
    if (story == null) {
      return;
    }

    try {
      await entityCacheManagerService.mutate([
        ...storyService.createDeleteStoryPhotoMutation(story, story.imageAssetIds[0]),
      ]);
    } catch (error) {
      captureException(error, true);
      toast('Failed to update profile', 'root-toast', 'error');
    }

    setStoryState(storyManager, { type: 'view', controls: 'visible', assistant: 'hidden' });
  }, [entityCacheManagerService, story, storyManager, storyService]);

  const handleSave = useCallback(() => {
    setInputValue(form, 'type', StoryDataType.HUMAN);
    onSave?.();
  }, [form, onSave]);

  const hasPhoto = !!(story?.imageAssetIds[0] && story?.imageAssetIds[0]?.length > 0);

  return (
    <>
      <StoryActions
        showNavigation={storiesIds.length > 1}
        canDownload={recordingAsset?.processingStatus === AssetProcessingStatus.PROCESSED}
        canReact={canReact}
        canEdit={canEdit}
        hasPhoto={hasPhoto}
        mode={storyState.type === 'editing' ? 'editing' : 'viewing'}
        saving={storyState.type === 'editing' && !!storyState.saving}
        onShare={handleShare}
        onDownload={handleDownloadStory}
        onReRecord={handleAskReRecordStory}
        onRemove={handleAskRemove}
        onReact={handleEditReaction}
        onUpdatePhoto={handleUpdatePhoto}
        onRemovePhoto={handleAskRemovePhoto}
        onEditSummary={onEditSummary}
        onEditCancel={onEditCancel}
        onSave={handleSave}
        onOpenRegenerate={onOpenRegenerate}
      />

      <RMConfirmationModal
        open={storyState.type === 'removing'}
        title="Remove story?"
        message="This action cannot be undone."
        type="danger"
        confirmLabel="Remove"
        onConfirm={handleRemoveStory}
        onCancel={handleCancelRemove}
        onClose={handleCancelRemove}
      />

      <RMConfirmationModal
        open={storyState.type === 're-recording'}
        title="Re-record story?"
        message="This action will create and send an identical prompt right now. You’ll be able to delete either at any time."
        confirmLabel="Re-send prompt"
        onConfirm={handleReRecordStory}
        onCancel={handleCancelReRecord}
        onClose={handleCancelReRecord}
      />

      <RMConfirmationModal
        open={storyState.type === 'photo-removing'}
        title="Remove photo?"
        message="Are you sure you want to delete this photo? This action cannot be undone."
        type="danger"
        confirmLabel="Yes, remove"
        onConfirm={handleRemovePhoto}
        onCancel={handleCancelRemove}
        onClose={handleCancelRemove}
      />
    </>
  );
}
