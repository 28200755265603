import { faImage, faRightLeft } from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowUpFromBracket,
  faCheck,
  faClose,
  faDownload,
  faEllipsis,
  faRotateRight,
  faSparkles,
  faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactionSentiment } from '@remento/types/reaction';

import addEmojiIcon from '@/assets/icons/add-emoji.svg';
import { RMBottomSheet } from '@/components/RMBottomSheet';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMSplitButton } from '@/components/RMSplitButton';
import { useIsFullscreen } from '@/hooks/useIsFullscreen';
import { useIsMobileOrTabletViewport } from '@/hooks/useIsMobileViewport';

import { StoryReactionButtons } from '../StoryReactionButtons/StoryReactionButtons';

import {
  ButtonsWrapper,
  CancelButton,
  ConfirmButton,
  IconButtonsWrapper,
  StoryActionsWrapper,
} from './StoryActions.styles';

interface StoryActionsProps {
  showNavigation: boolean;
  canDownload: boolean;
  canReact: boolean;
  canEdit: boolean;
  hasPhoto: boolean;
  mode: 'viewing' | 'editing';
  saving: boolean;
  onPrevious?: () => void;
  onNext?: () => void;
  onShare: () => void;
  onDownload: () => void;
  onReRecord: () => void;
  onRemove: () => void;
  onReact: (sentiment?: ReactionSentiment) => void;
  onUpdatePhoto: () => void;
  onRemovePhoto: () => void;
  onEditSummary?: () => void;
  onEditCancel?: () => void;
  onOpenRegenerate?: () => void;
  onSave?: () => void;
}

export function StoryActions({
  canDownload,
  canReact,
  canEdit,
  hasPhoto,
  mode,
  saving,
  onShare,
  onDownload,
  onReRecord,
  onRemove,
  onReact,
  onUpdatePhoto,
  onRemovePhoto,
  onEditSummary,
  onEditCancel,
  onSave,
  onOpenRegenerate,
}: StoryActionsProps) {
  const isMobileOrTablet = useIsMobileOrTabletViewport();
  const isFullscreen = useIsFullscreen();

  if (isFullscreen) {
    return null;
  }

  return (
    <StoryActionsWrapper data-editing={mode === 'editing'}>
      {mode === 'editing' ? (
        <>
          <RMButton
            background="neutral"
            leftIcon={faSparkles}
            onClick={(event) => {
              event.stopPropagation();
              onOpenRegenerate?.();
            }}
            disabled={saving}
          >
            Writing assistant
          </RMButton>
          <ButtonsWrapper>
            {isMobileOrTablet ? (
              <>
                <CancelButton
                  onClick={(event) => {
                    event.stopPropagation();
                    onEditCancel?.();
                  }}
                  disabled={saving}
                >
                  <FontAwesomeIcon size="lg" icon={faClose} color="white" />
                </CancelButton>
                <ConfirmButton
                  onClick={(event) => {
                    event.stopPropagation();
                    onSave?.();
                  }}
                  disabled={saving}
                >
                  <FontAwesomeIcon size="lg" icon={faCheck} color="white" />
                </ConfirmButton>
              </>
            ) : (
              <>
                <RMButton
                  background="danger"
                  disabled={saving}
                  onClick={(event) => {
                    event.stopPropagation();
                    onEditCancel?.();
                  }}
                >
                  Cancel
                </RMButton>
                <RMButton
                  background="affirmative"
                  loading={saving}
                  onClick={(event) => {
                    event.stopPropagation();
                    onSave?.();
                  }}
                >
                  Save
                </RMButton>
              </>
            )}
          </ButtonsWrapper>
        </>
      ) : (
        <>
          <IconButtonsWrapper>
            {isMobileOrTablet && canEdit && (
              <RMIconButton
                tooltip={{ label: 'Share', position: 'top' }}
                backgroundColor="white"
                icon={faArrowUpFromBracket}
                onClick={(event) => {
                  event.stopPropagation();
                  onShare();
                }}
              />
            )}

            {canReact && (canEdit || isMobileOrTablet) && (
              <RMIconButton
                tooltip={{ label: 'React', position: 'top' }}
                backgroundColor="white"
                onClick={(event) => {
                  event.stopPropagation();
                  onReact();
                }}
              >
                <img src={addEmojiIcon} />
              </RMIconButton>
            )}

            {canReact && canEdit == false && isMobileOrTablet == false && (
              <StoryReactionButtons theme="light" onSelectReaction={onReact} />
            )}

            {canEdit && (
              <RMBottomSheet
                Trigger={<RMIconButton as="div" icon={faEllipsis} tooltip={null} backgroundColor="white" />}
                options={[
                  {
                    label: 'Download recording',
                    value: 'download',
                    icon: faDownload,
                    disabled: !canDownload,
                    onSelect: onDownload,
                  },
                  {
                    label: 'Re-record story',
                    value: 'rerecord',
                    icon: faRotateRight,
                    onSelect: onReRecord,
                  },
                  ...(hasPhoto
                    ? [
                        {
                          label: 'Change photo',
                          value: 'change-photo',
                          icon: faRightLeft,
                          onSelect: onUpdatePhoto,
                        },
                        {
                          label: 'Remove photo',
                          value: 'remove-photo',
                          icon: faImage,
                          onSelect: onRemovePhoto,
                        },
                      ]
                    : [
                        {
                          label: 'Add photo',
                          value: 'add-photo',
                          icon: faImage,
                          onSelect: onUpdatePhoto,
                        },
                      ]),

                  {
                    label: 'Delete story',
                    value: 'delete-story',
                    icon: faTrashCan,
                    destructive: true,
                    onSelect: onRemove,
                  },
                ]}
              />
            )}
          </IconButtonsWrapper>

          <ButtonsWrapper>
            {canEdit == false && (
              <RMButton
                onClick={(event) => {
                  event.stopPropagation();
                  onEditSummary?.();
                }}
              >
                View more
              </RMButton>
            )}

            {(isMobileOrTablet == false || canEdit == false) && (
              <RMButton
                background={canEdit ? 'neutral' : 'primary'}
                leftIcon={faArrowUpFromBracket}
                onClick={(event) => {
                  event.stopPropagation();
                  onShare();
                }}
              >
                Share
              </RMButton>
            )}

            {canEdit == true && (
              <RMSplitButton
                primaryLabel="Edit story"
                secondaryIcon={faSparkles}
                secondaryTooltip="Writing assistant"
                onPrimaryClick={(event) => {
                  event.stopPropagation();
                  onEditSummary?.();
                }}
                onSecondaryClick={(event) => {
                  event.stopPropagation();
                  onOpenRegenerate?.();
                }}
              />
            )}
          </ButtonsWrapper>
        </>
      )}
    </StoryActionsWrapper>
  );
}
