import { useMemo } from 'react';
import { faSend, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { ReactionSentiment, ReactionSentimentEmoji } from '@remento/types/reaction';

import CheckmarkLottieAnimation from '@/@assets/checkmark-lottie-animation.json';
import SpinnerLottieAnimation from '@/assets/spinner.lottie.json';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { InputContainer, InputController } from '@/modules/form/container';
import { useIsFormSubmitting, useIsFormValid } from '@/modules/form/form';
import { useInputInteraction } from '@/modules/form/input';

import { StoryReactionForm } from '../../forms/story-reaction.form';

import {
  Emoji,
  EmojiList,
  LottieCheckmark,
  LottieSpinner,
  LottieWrapper,
  SubmittedContent,
} from './StoryReactionDialog.styles';

interface StoryReactionDialogProps {
  showSentiments: boolean;
  open: boolean;
  form: StoryReactionForm;
  firstName: string;
  state: 'input' | 'loading' | 'submitted';
  onConfirm: () => void;
  onClose: () => void;
  onChangeSentiment: (sentiment: ReactionSentiment) => void;
}

const SENTIMENTS = Object.keys(ReactionSentiment) as ReactionSentiment[];

export function StoryReactionDialog({
  showSentiments,
  open,
  form,
  firstName,
  state,
  onConfirm,
  onClose,
  onChangeSentiment,
}: StoryReactionDialogProps) {
  const isEditing = useInputInteraction(form, 'message').isDirty;
  const isSubmitting = useIsFormSubmitting(form);
  const formValid = useIsFormValid(form);

  return (
    <RMDialog.Root open={open} onClose={onClose}>
      <RMDialog.Content>
        {state !== 'submitted' && (
          <RMDialog.Header
            title="Send a reaction"
            message={`We'll email ${firstName} your reaction to this story.`}
            rightAdornment={<RMCloseButton onClick={onClose} />}
          />
        )}

        <RMDialog.Body>
          <RMDialog.Transition>
            {state === 'submitted' ? (
              <SubmittedContent>
                <LottieCheckmark animationData={CheckmarkLottieAnimation} />
                <RMText type="sans" bold size="xs" color="on-surface-secondary">
                  Reaction sent!
                </RMText>
              </SubmittedContent>
            ) : (
              <>
                {state === 'loading' ? (
                  <LottieWrapper>
                    <LottieSpinner loop animationData={SpinnerLottieAnimation} />
                  </LottieWrapper>
                ) : (
                  <>
                    {showSentiments && (
                      <InputController form={form} path="sentiment">
                        {(props) => (
                          <EmojiList>
                            {SENTIMENTS.map((sentiment) => (
                              <Emoji
                                key={sentiment}
                                data-selected={props.value === sentiment}
                                data-inactive={isEditing && props.value !== sentiment}
                                data-disabled={isSubmitting}
                                onClick={() => onChangeSentiment(sentiment)}
                              >
                                {ReactionSentimentEmoji[sentiment]}
                              </Emoji>
                            ))}
                          </EmojiList>
                        )}
                      </InputController>
                    )}
                    <RMSpacer spacing="xl" direction="column" />
                    <InputContainer form={form} path="message">
                      {(props) => (
                        <RMInput
                          placeholder="Type your message..."
                          validation={false}
                          id="message"
                          textarea
                          fontFamily="sans"
                          disabled={isSubmitting}
                          {...props}
                        />
                      )}
                    </InputContainer>
                  </>
                )}
              </>
            )}
          </RMDialog.Transition>
        </RMDialog.Body>

        {state !== 'submitted' && (
          <RMDialog.Footer>
            <RMButton
              fullWidth
              leftIcon={faSend}
              background="primary"
              disabled={state === 'loading' || !formValid}
              autoLoading
              onClick={onConfirm}
            >
              Send reaction
            </RMButton>
          </RMDialog.Footer>
        )}
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
