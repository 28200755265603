import { ReactionSentiment, ReactionSentimentEmoji } from '@remento/types/reaction';

import { Container, ReactionIcon } from './StoryReactionButtons.styles';

const reactions = Object.entries(ReactionSentimentEmoji) as [key: ReactionSentiment, value: string][];

export interface StoryReactionButtonsProps {
  theme?: 'dark' | 'light';
  currentReaction?: ReactionSentiment | null;
  onSelectReaction: (reactionSentiment: ReactionSentiment) => void;
}

export function StoryReactionButtons({ theme = 'dark', currentReaction, onSelectReaction }: StoryReactionButtonsProps) {
  return (
    <Container data-theme={theme}>
      {reactions.map(([reactionSentiment, emoji]) => (
        <ReactionIcon
          key={reactionSentiment}
          data-theme={theme}
          data-active={currentReaction === reactionSentiment}
          onClick={() => onSelectReaction(reactionSentiment)}
        >
          {emoji}
        </ReactionIcon>
      ))}
    </Container>
  );
}
