import { ReactionSentiment } from '@remento/types/reaction';
import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export function createStoryReactionFormSchema() {
  return z.object({
    sentiment: z.nativeEnum(ReactionSentiment).optional(),
    message: z.string().min(1),
  });
}

export type StoryReactionFormInput = z.input<ReturnType<typeof createStoryReactionFormSchema>>;
export type StoryReactionFormOutput = z.output<ReturnType<typeof createStoryReactionFormSchema>>;

export type StoryReactionForm = Form<StoryReactionFormInput, StoryReactionFormOutput>;

export function createStoryReactionForm(defaultValues?: DeepPartial<StoryReactionFormInput>): StoryReactionForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createStoryReactionFormSchema(),
  });
}
