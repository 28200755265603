import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConflictError, ConflictErrorType } from '@remento/types/error';
import { ReactionSentiment, ReactionStatus } from '@remento/types/reaction';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { toast } from '@/components/RMToast/RMToast';
import { getQueryParam } from '@/hooks/useQueryParam';
import { resetForm, submitForm } from '@/modules/form/form';
import { getInputInteraction, getInputState, getInputValue, setInputValue, setInputValues } from '@/modules/form/input';
import { getSigninPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { StoryPage } from '@/services/analytics/story-viewer-analytics/story-viewer-analytics.types';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useReactionSuggestionBySentiment } from '@/services/api/reaction';
import { useRecordingQuery } from '@/services/api/recording';
import { captureException } from '@/utils/captureException';
import { secureUuid } from '@/utils/uuid';

import { RequestAccessDialog } from '../components/RequestAccessDialog/RequestAccessDialog';
import { StoryReactionDialog } from '../components/StoryReactionDialog/StoryReactionDialog';
import { createRequestAccessForm } from '../forms/request-access.form';
import { createStoryReactionForm } from '../forms/story-reaction.form';
import {
  REACTION_MESSAGE_PARAM_NAME,
  REACTION_SENTIMENT_PARAM_NAME,
  useSyncStoryReactionForm,
} from '../hooks/useSyncStoryReactionForm';

export interface StoryAnonymousReactionDialogContainerProps {
  open: boolean;
  recordingId: string;
  initialSentiment: ReactionSentiment | null;
  page: StoryPage;
  onClose: () => void;
}

interface ReactionState {
  type: 'reaction';
}

interface RequestAccessState {
  type: 'request-access';
  reaction: {
    sentiment?: ReactionSentiment;
    message: string;
  };
}

interface DoneState {
  type: 'submitted';
}

type DialogState = ReactionState | RequestAccessState | DoneState;

export function StoryAnonymousReactionDialogContainer({
  open,
  recordingId,
  initialSentiment,
  page,
  onClose,
}: StoryAnonymousReactionDialogContainerProps) {
  const [state, setState] = useState<DialogState>({ type: 'reaction' });

  // Common
  const {
    redirectService,
    projectCacheService,
    reactionCacheService,
    collaborationAnalyticsService,
    storyViewerAnalyticsService,
  } = useServices();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const user = useUser();
  const personQuery = usePersonQuery(user?.personId);

  // Reaction
  const suggestionsBySentiment = useReactionSuggestionBySentiment(recordingId);
  const recordingQuery = useRecordingQuery(recordingId);
  const authorPersonQuery = usePersonQuery(recordingQuery.data?.personId ?? null);
  const [desiredSentiment, setDesiredSentiment] = useState<ReactionSentiment | null>(null);
  const reactionForm = useMemo(() => createStoryReactionForm(), []);
  const { clearStoryReactionFormParams } = useSyncStoryReactionForm(reactionForm);

  const handleSentimentChange = useCallback(
    (sentiment: ReactionSentiment) => {
      // This will never happen.
      if (state.type !== 'reaction') {
        return;
      }
      // Don't do anything if the sentiment is the same
      if (sentiment === getInputValue(reactionForm, 'sentiment')) {
        return;
      }

      const isMessageEdited = getInputInteraction(reactionForm, 'message').isDirty;

      if (isMessageEdited && desiredSentiment !== sentiment) {
        setDesiredSentiment(sentiment);
      } else {
        resetForm(reactionForm);
        setInputValue(reactionForm, 'sentiment', sentiment);
        setInputValue(reactionForm, 'message', suggestionsBySentiment[sentiment] ?? '');

        setDesiredSentiment(null);
      }
    },
    [state.type, reactionForm, desiredSentiment, suggestionsBySentiment],
  );

  const handleSubmitReaction = useCallback(async () => {
    await submitForm(reactionForm, (data) => {
      setState({
        type: 'request-access',
        reaction: data,
      });
    });
  }, [reactionForm]);

  // Sync the reaction form state with the query params
  useEffect(() => {
    if (open == false) {
      return;
    }

    const sentiment =
      (getQueryParam(REACTION_SENTIMENT_PARAM_NAME) as ReactionSentiment) ?? initialSentiment ?? ReactionSentiment.LOVE;
    const message = getQueryParam(REACTION_MESSAGE_PARAM_NAME) ?? suggestionsBySentiment[sentiment] ?? '';

    resetForm(reactionForm);
    setInputValue(reactionForm, 'sentiment', sentiment);
    setInputValue(reactionForm, 'message', message);
  }, [initialSentiment, open, reactionForm, suggestionsBySentiment]);

  const handleClose = useCallback(() => {
    onClose();
    clearStoryReactionFormParams();
  }, [clearStoryReactionFormParams, onClose]);

  // Request access
  const requestAccessForm = useMemo(() => createRequestAccessForm(), []);

  const handleRequestAccess = useCallback(async () => {
    if (recordingQuery.data == null) {
      return;
    }

    await submitForm(requestAccessForm, async (data) => {
      // This will never happen.
      if (state.type != 'request-access') {
        return;
      }

      try {
        const newUser = await projectCacheService.requestAccess(recordingQuery.data.projectId, data);
        await reactionCacheService.createReaction({
          id: secureUuid(),
          status: ReactionStatus.SUBMITTED,
          sentiment: state.reaction.sentiment,
          message: state.reaction.message,
          personId: newUser.personId,
          userId: newUser.id,
          recordingId,
        });
        setState({ type: 'submitted' });
        setTimeout(() => {
          handleClose();
          resetForm(reactionForm);
          resetForm(requestAccessForm);
          setState({ type: 'reaction' });
        }, 2500);
        collaborationAnalyticsService.onAccessRequested(page, 'reaction');
        storyViewerAnalyticsService.onReactionSent(
          page,
          state.reaction.sentiment ?? null,
          getInputState(reactionForm, 'message').isDirty,
        );
      } catch (error) {
        if (error instanceof ConflictError && error.data?.type == ConflictErrorType.USER_ALREADY_EXISTS) {
          toast('A user already exists with this email. Please sign in.', 'root-toast', 'error');
          return;
        }
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    });
  }, [
    collaborationAnalyticsService,
    handleClose,
    page,
    projectCacheService,
    reactionCacheService,
    reactionForm,
    recordingId,
    recordingQuery.data,
    requestAccessForm,
    state,
    storyViewerAnalyticsService,
  ]);

  const handleSignIn = useCallback(async () => {
    await redirectService.registerRedirect('signed-in', window.location.pathname + window.location.search);
    navigate(getSigninPath({ backupLocalData: true }));
  }, [navigate, redirectService]);

  const handleSignOut = useCallback(async () => {
    await redirectService.registerRedirect('signed-out', window.location.pathname + window.location.search);
    await signOut();
  }, [redirectService, signOut]);

  const handleCloseRequestAccessDialog = useCallback(() => {
    handleClose();
    setState({ type: 'reaction' });
  }, [handleClose]);

  // Set the initial request access form values
  useEffect(() => {
    if (user != null && personQuery.data != null) {
      setInputValues(requestAccessForm, {
        firstName: '',
        lastName: '',
        email: '',
      });
    }

    setInputValues(requestAccessForm, {
      firstName: personQuery.data?.name?.first,
      lastName: personQuery.data?.name?.last,
      email: user?.communicationChannels.email,
    });
  }, [personQuery.data, requestAccessForm, user]);

  return (
    <>
      <StoryReactionDialog
        open={open && state.type == 'reaction'}
        showSentiments
        firstName={authorPersonQuery.data?.name?.first ?? ''}
        form={reactionForm}
        state="input"
        onChangeSentiment={handleSentimentChange}
        onConfirm={handleSubmitReaction}
        onClose={handleClose}
      />
      <RequestAccessDialog
        open={open && (state.type == 'request-access' || state.type == 'submitted')}
        form={requestAccessForm}
        disabled={user != null}
        isSignedIn={user != null}
        signedInUserName={personQuery.data?.name?.first ?? null}
        success={state.type == 'submitted'}
        title="Request access to this project"
        subtitle="Project members can view all stories, share reactions and more."
        successTitle="Reaction sent!"
        successSubtitle={`We’ve sent your reaction to ${authorPersonQuery.data?.name?.first} along with your request to join this project.`}
        onRequestAccess={handleRequestAccess}
        onSignIn={handleSignIn}
        onSignOut={handleSignOut}
        onClose={handleCloseRequestAccessDialog}
      />

      <RMConfirmationModal
        open={desiredSentiment !== null}
        type="danger"
        title="Discard message?"
        message="Selecting a new reaction will discard your message."
        confirmLabel="Yes, discard"
        cancelLabel="Nevermind"
        onConfirm={() => {
          if (desiredSentiment != null) {
            handleSentimentChange(desiredSentiment);
          }
        }}
        onCancel={() => setDesiredSentiment(null)}
      />
    </>
  );
}
