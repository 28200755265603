import { StoryLengthType, StoryPerspectiveType } from '@remento/types/story';
import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export function createStoryRegenerateFormSchema() {
  return z.object({
    perspective: z.nativeEnum(StoryPerspectiveType),
    length: z.nativeEnum(StoryLengthType),
  });
}

export type StoryRegenerateFormInput = z.input<ReturnType<typeof createStoryRegenerateFormSchema>>;
export type StoryRegenerateFormOutput = z.output<ReturnType<typeof createStoryRegenerateFormSchema>>;

export type StoryRegenerateForm = Form<StoryRegenerateFormInput, StoryRegenerateFormOutput>;

export function createStoryRegenerateForm(defaultValues?: DeepPartial<StoryRegenerateFormInput>): StoryRegenerateForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createStoryRegenerateFormSchema(),
  });
}
