import { useState } from 'react';
import clsx from 'clsx';

import { Player } from '@/modules/media-player/Player';

import {
  Container,
  Content,
  fitContain,
  fitCover,
  Placeholder,
  playerStyles,
  shimmerStyles,
} from './StoryVideoPlayer.styles';

interface StoryVideoPlayerProps {
  fitMode?: 'cover' | 'contain';
}

export function StoryVideoPlayer({ fitMode = 'cover' }: StoryVideoPlayerProps) {
  const [loaded, setLoaded] = useState(false);

  return (
    <Container>
      <Content>
        <Player
          className={clsx([playerStyles, !loaded && shimmerStyles, fitMode === 'contain' ? fitContain : fitCover])}
          // We cannot use the onCanPlay or onLoadedData because these events will not fire
          // when low power mode is active.
          onLoadedMetadata={() => setLoaded(true)}
        />
        <Placeholder />
      </Content>
    </Container>
  );
}
