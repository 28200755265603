import { useCallback } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { createStore, StoreApi, useStore } from 'zustand';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { getActiveRoute } from '@/utils/getActiveRoute';

import { getStoriesPath, getStoryViewPath } from '../../routing';

export type StorySummaryMode = 'closed' | 'edit' | 'view';

export interface ActiveStoryState {
  id: string;
  index: number;
}

export interface StoriesManagerState {
  storiesIds: string[];
  activeStory: ActiveStoryState | null;
  shareOpen: boolean;
}

export type StoriesManagerStore = StoreApi<StoriesManagerState>;

export type StoriesManagerType = 'story' | 'story-standalone' | 'highlight-reel-standalone';

export interface StoriesManager {
  store: StoriesManagerStore;
  defaultStoryId: string | null;
  projectId: string;
  navigate: NavigateFunction | null;
  type: StoriesManagerType;
  showIssues: boolean;
}

export function createStoriesManager(
  storiesIds: string[],
  projectId: string,
  defaultStoryId: string | null,
  navigate: NavigateFunction | null,
  type: StoriesManagerType = 'story',
  showIssues = false,
): StoriesManager {
  const store = createStore<StoriesManagerState>(() => ({
    storiesIds,
    activeStory: null,
    controlsVisible: true,
    shareOpen: false,
  }));

  const manager = {
    store,
    defaultStoryId,
    projectId,
    type,
    navigate,
    showIssues,
  };

  if (storiesIds.length > 0) {
    setStoriesIds(manager, storiesIds);
  }

  return manager;
}

export function setStoriesIds(manager: StoriesManager, storiesIds: string[]): void {
  manager.store.setState((state) => {
    let activeStory: ActiveStoryState | null = null;

    const id = state.activeStory?.id ?? manager.defaultStoryId;
    if (id) {
      const index = storiesIds.indexOf(id);
      if (index !== -1) {
        activeStory = {
          id,
          index,
        };
      }
    }

    return {
      ...state,
      storiesIds,
      activeStory,
      shareOpen: activeStory === null ? false : state.shareOpen,
    };
  });
}

export function getStoriesIds(manager: StoriesManager): string[] {
  return manager.store.getState().storiesIds;
}

export function useStoriesIds(manager: StoriesManager): string[] {
  return useStore(
    manager.store,
    useCallback(({ storiesIds }) => storiesIds, []),
  );
}

export function hasNextStory(manager: StoriesManager): boolean {
  const { activeStory, storiesIds } = manager.store.getState();
  if (activeStory === null) {
    return false;
  }
  return activeStory.index < storiesIds.length - 1;
}

export function hasPreviousStory(manager: StoriesManager): boolean {
  const { activeStory } = manager.store.getState();
  if (activeStory === null) {
    return false;
  }
  return activeStory.index > 0;
}

export function useHasNextStory(manager: StoriesManager): boolean {
  return useStore(
    manager.store,
    useCallback(({ activeStory, storiesIds }) => {
      if (activeStory === null) {
        return false;
      }
      return activeStory.index < storiesIds.length - 1;
    }, []),
  );
}

export function useHasPreviousStory(manager: StoriesManager): boolean {
  return useStore(
    manager.store,
    useCallback(({ activeStory }) => {
      if (activeStory === null) {
        return false;
      }
      return activeStory.index > 0;
    }, []),
  );
}

export function getActiveStoryId(manager: StoriesManager): string | null {
  return manager.store.getState().activeStory?.id ?? null;
}

export function useActiveStoryId(manager: StoriesManager): string | null {
  return useStore(
    manager.store,
    useCallback(({ activeStory }) => {
      return activeStory?.id ?? null;
    }, []),
  );
}

export function useActiveStoryIndex(manager: StoriesManager): number {
  return useStore(
    manager.store,
    useCallback(({ activeStory }) => {
      return activeStory?.index ?? -1;
    }, []),
  );
}

export function setActiveStoryId(manager: StoriesManager, activeStoryId: string | null): void {
  manager.store.setState((state) => {
    const currentRoute = getActiveRoute();
    const shouldNavigate = currentRoute?.params.storyId != activeStoryId;

    let activeStory: ActiveStoryState | null = null;
    if (activeStoryId) {
      const index = state.storiesIds.indexOf(activeStoryId);
      if (index !== -1) {
        activeStory = {
          id: activeStoryId,
          index,
        };

        if (shouldNavigate) manager.navigate?.(getStoryViewPath(manager.projectId, activeStoryId));
      } else {
        if (shouldNavigate) manager.navigate?.(getStoriesPath(manager.projectId));
      }
    } else {
      if (shouldNavigate) manager.navigate?.(getStoriesPath(manager.projectId));
    }

    return {
      ...state,
      activeStory,
    };
  });
}

export function nextStory(manager: StoriesManager): boolean {
  const { activeStory, storiesIds } = manager.store.getState();

  const activeIndex = activeStory?.index ?? -1;
  if (activeIndex === -1) {
    return false;
  }

  if (hasNextStory(manager)) {
    setActiveStoryId(manager, storiesIds[activeIndex + 1]);
    return true;
  }

  return false;
}

export function previousStory(manager: StoriesManager): boolean {
  const { activeStory, storiesIds } = manager.store.getState();

  const activeIndex = activeStory?.index ?? -1;
  if (activeIndex === -1) {
    return false;
  }

  if (hasPreviousStory(manager)) {
    setActiveStoryId(manager, storiesIds[activeIndex - 1]);
    return true;
  }

  return false;
}

export function useNextStoryId(manager: StoriesManager): string | null {
  return useStore(
    manager.store,
    useCallback(({ activeStory, storiesIds }) => {
      const activeIndex = activeStory?.index ?? -1;
      return storiesIds[activeIndex + 1] ?? null;
    }, []),
  );
}

export function usePreviousStoryId(manager: StoriesManager): string | null {
  return useStore(
    manager.store,
    useCallback(({ activeStory, storiesIds }) => {
      const activeIndex = activeStory?.index ?? -1;
      return storiesIds[activeIndex - 1] ?? null;
    }, []),
  );
}

export function useShouldLoadStory(manager: StoriesManager, storyId: string): boolean {
  const isMobile = useIsMobileViewport();

  return useStore(manager.store, (state) => {
    if (state.activeStory == null) {
      return false;
    }

    if (isMobile) {
      return state.activeStory.id === storyId;
    }

    const storyIndex = state.storiesIds.indexOf(storyId);

    // Returns true if it's the active story or the previous/next 2 ones
    return Math.abs(storyIndex - state.activeStory.index) <= 2;
  });
}
