import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { LottieAnimation } from '@/components/LottieAnimation';
import { RMText } from '@/components/RMText/RMText';

export const StyledStoryRegeneratePreview = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  background: var(--surface);
  box-shadow: var(--elevation-top-3);
  /* The z-index should be the same as the RMDialog */
  z-index: 999;

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    position: fixed;
  }
`;

export const Header = styled.div`
  z-index: 1;
  padding: var(--spacing-2xl) 0;
  position: sticky;
  top: 0;
  background: var(--surface);

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    padding: var(--spacing-md) 0;
  }
`;

export const Badge = styled(RMText)`
  border-radius: var(--radius-minimal);
  border: 1px solid var(--primary);
  padding: var(--spacing-2xs) var(--spacing-xs);
  letter-spacing: 1px;
`;

export const Body = styled.div`
  padding: 0 var(--spacing-2xl);
  height: 100%;
  overflow: auto;

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    padding: 0 var(--spacing-xl);
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--surface);
  border-top: 1px solid var(--border-weak);
  padding: var(--spacing-md) var(--spacing-xl);
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
`;

export const summaryClass = css`
  font-family: var(--font-serif);
  font-size: 1.25rem;
  line-height: 140%;
  color: var(--on-surface-secondary);
  border: 1px solid transparent;
  margin-bottom: calc(5rem + var(--spacing-sm));
  border-radius: var(--radius-round);
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  min-height: 8rem;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
  cursor: text;

  p {
    margin-bottom: 1rem;
  }
`;

export const titleClass = css`
  font-family: var(--font-serif);
  font-size: 2rem;
  line-height: 140%;
  color: var(--on-surface-primary);
  border: 1px solid transparent;
  border-radius: var(--radius-round);
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  margin-bottom: var(--spacing-xl);
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
  cursor: text;

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    font-size: 1.5625rem;
    margin-bottom: var(--spacing-md);
  }
`;

export const LoadingContent = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LottieSpinner = styled(LottieAnimation)`
  position: relative;
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;
`;
