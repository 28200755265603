import EmailValidator from 'email-validator';
import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export function createRequestAccessFormSchema() {
  return z.object({
    firstName: z.string().min(1, 'Enter your first name'),
    lastName: z.string().min(1, 'Enter your last name'),
    email: z.string().refine(EmailValidator.validate, { message: 'Enter a valid email address' }),
  });
}

export type RequestAccessFormInput = z.input<ReturnType<typeof createRequestAccessFormSchema>>;
export type RequestAccessFormOutput = z.output<ReturnType<typeof createRequestAccessFormSchema>>;

export type RequestAccessForm = Form<RequestAccessFormInput, RequestAccessFormOutput>;

export function createRequestAccessForm(defaultValues?: DeepPartial<RequestAccessFormInput>): RequestAccessForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createRequestAccessFormSchema(),
  });
}
