import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { STORY_ISSUE_DESCRIPTION, STORY_ISSUE_TITLE, StoryIssueType } from '@/services/api/story';

import { IssuesList, IssuesListItem } from './StoryIssuesDialog.styles';

export interface StoryIssuesDialogProps {
  open: boolean;
  issues: StoryIssueType[];
  onClose: () => void;
}

export function StoryIssuesDialog({ open, issues, onClose }: StoryIssuesDialogProps) {
  return (
    <RMDialog.Root open={open} onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title={`We found ${issues.length} potential issue${issues.length > 1 ? 's' : ''} with this story`}
          rightAdornment={<RMCloseButton onClick={onClose} />}
        />
        <RMDialog.Body>
          <IssuesList>
            {issues.map((issue, index) => (
              <IssuesListItem key={issue}>
                <RMText type="sans" size="s" color="on-surface-primary" bold>
                  {index + 1}. {STORY_ISSUE_TITLE[issue]}
                </RMText>
                <RMText type="sans" size="s" color="on-surface-primary">
                  {STORY_ISSUE_DESCRIPTION[issue]}
                </RMText>
              </IssuesListItem>
            ))}
          </IssuesList>
        </RMDialog.Body>
        <RMDialog.Footer>
          <RMButton background="primary" onClick={onClose}>
            Got it
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
