import { ReactNode } from 'react';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence } from 'framer-motion';

import rementoBookSrc from '@/assets/remento-book.webp';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { NoNewLine } from '@/components/RMTextEditor/extensions/no-new-line';
import { RMTextEditorStore } from '@/components/RMTextEditor/RMTextEditor.state';
import { RMTextEditorInputController } from '@/components/RMTextEditor/RMTextEditorInputController';
import { useIsMobileOrTabletViewport } from '@/hooks/useIsMobileViewport';
import { StoryIssueType } from '@/services/api/story';

import { StorySummaryForm } from '../../forms/story-summary.form';

import {
  Content,
  LearnMoreButton,
  LearnMoreButtonIcon,
  LearnMoreButtonImage,
  LearnMoreButtonText,
  ScrollArea,
  StoryActionsWrapper,
  StoryIssues,
  StoryIssuesWrapper,
  StyledStorySummary,
  summaryInputClass,
  titleInputClass,
  ViewStoryIssuesButton,
} from './StorySummary.styles';

export interface StorySummaryProps {
  form: StorySummaryForm;
  titleTextEditor: RMTextEditorStore;
  summaryTextEditor: RMTextEditorStore;
  state: 'editing' | 'viewing' | 'regenerating';
  saving: boolean;
  showLearnMore: boolean;
  issues: StoryIssueType[] | null;
  onViewIssues: () => void;
  onLearnMore: () => void;
  StoryActions?: ReactNode;
  StoryRegenerate?: ReactNode;
}

export function StorySummary({
  form,
  titleTextEditor,
  summaryTextEditor,
  state,
  saving,
  showLearnMore,
  issues,
  onViewIssues,
  onLearnMore,
  StoryActions,
  StoryRegenerate,
}: StorySummaryProps) {
  const isMobileOrTablet = useIsMobileOrTabletViewport();

  return (
    <StyledStorySummary>
      <Content>
        <ScrollArea>
          <RMTextEditorInputController
            form={form}
            path="title"
            editable={state === 'editing' && saving === false}
            className={titleInputClass}
            store={titleTextEditor}
            extensions={[NoNewLine]}
          />
          <RMSpacer spacing={isMobileOrTablet ? 'xs' : 'md'} direction="column" />
          <RMTextEditorInputController
            form={form}
            path="summary"
            editable={state === 'editing' && saving === false}
            className={summaryInputClass}
            store={summaryTextEditor}
          />

          {isMobileOrTablet && showLearnMore && (
            <LearnMoreButton>
              <LearnMoreButtonImage src={rementoBookSrc} onClick={onLearnMore} />
              <LearnMoreButtonText>
                Capture your own family’s stories
                <LearnMoreButtonIcon icon={faChevronRight} />
              </LearnMoreButtonText>
            </LearnMoreButton>
          )}
          {issues != null && issues.length > 0 && <RMSpacer direction="column" spacing="3xl" />}

          {isMobileOrTablet && <RMSpacer direction="column" spacing={'4xl'} />}
        </ScrollArea>

        {issues != null && issues.length > 0 && isMobileOrTablet == false && (
          <StoryIssuesWrapper>
            <StoryIssues>
              <FontAwesomeIcon icon={faTriangleExclamation} color="var(--caution)" />
              <RMText type="sans" size="xs" color="caution" bold>
                We’ve found {issues.length} potential issue{issues.length > 1 ? 's' : ''} with this story
              </RMText>
              <ViewStoryIssuesButton onClick={onViewIssues}>
                <RMText type="sans" size="xs" color="caution" bold>
                  View
                </RMText>
              </ViewStoryIssuesButton>
            </StoryIssues>
          </StoryIssuesWrapper>
        )}

        <StoryActionsWrapper data-editing={state === 'editing'}>{StoryActions}</StoryActionsWrapper>

        {<AnimatePresence>{state === 'regenerating' && StoryRegenerate}</AnimatePresence>}
      </Content>
    </StyledStorySummary>
  );
}
