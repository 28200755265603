import { faClose } from '@fortawesome/pro-solid-svg-icons';

import CheckmarkLottieAnimation from '@/@assets/checkmark-lottie-animation.json';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer } from '@/modules/form/container';

import { RequestAccessForm } from '../../forms/request-access.form';

import {
  DialogContent,
  Footer,
  Form,
  LottieCheckMark,
  SuccessAnimationContainer,
  SuccessTextContainer,
} from './RequestAccessDialog.styles';

export interface RequestAccessDialogProps {
  open: boolean;
  form: RequestAccessForm;
  disabled: boolean;
  isSignedIn: boolean;
  signedInUserName: string | null;
  success: boolean;
  title?: string;
  subtitle?: string;
  successTitle?: string;
  successSubtitle?: string;
  onRequestAccess: () => void;
  onSignIn: () => void;
  onSignOut: () => void;
  onClose: () => void;
}

const DEFAULT_TITLE = 'Request access to edit';
const DEFAULT_SUBTITLE =
  "This story was created by Remento's Speech-to-Story technology. Collaborators can edit the text. Request access here.";
const DEFAULT_SUCCESS_TITLE = 'Request sent!';

export function RequestAccessDialog({
  open,
  form,
  disabled,
  isSignedIn,
  signedInUserName,
  success,
  title = DEFAULT_TITLE,
  subtitle = DEFAULT_SUBTITLE,
  successTitle = DEFAULT_SUCCESS_TITLE,
  successSubtitle,
  onRequestAccess,
  onSignIn,
  onSignOut,
  onClose,
}: RequestAccessDialogProps) {
  const isMobile = useIsMobileViewport();

  return (
    <RMDialog.Root open={open} variant={isMobile ? 'full-screen' : 'regular'}>
      <DialogContent>
        <RMDialog.Header title={title} rightAdornment={<RMCloseButton onClick={onClose} />} />

        <RMDialog.Body>
          <RMDialog.Transition>
            {success && (
              <SuccessAnimationContainer>
                <LottieCheckMark animationData={CheckmarkLottieAnimation} />
                <SuccessTextContainer>
                  <RMText type="sans" bold size="xs" color="on-surface-secondary">
                    {successTitle}
                  </RMText>
                  {successSubtitle && (
                    <RMText type="sans" size="xs" color="on-surface-secondary" align="center">
                      {successSubtitle}
                    </RMText>
                  )}
                </SuccessTextContainer>
              </SuccessAnimationContainer>
            )}
            <RMText type="sans" size="xs" color="on-surface-primary">
              {subtitle}
            </RMText>

            <Form>
              <InputContainer form={form} path="firstName">
                {(props) => (
                  <RMInput
                    id="request-access-first-name"
                    label="First name"
                    placeholder="Jane"
                    disabled={disabled}
                    {...props}
                  />
                )}
              </InputContainer>
              <InputContainer form={form} path="lastName">
                {(props) => (
                  <RMInput
                    id="request-access-last-name"
                    label="Last name"
                    placeholder="Doe"
                    disabled={disabled}
                    {...props}
                  />
                )}
              </InputContainer>
              <InputContainer form={form} path="email">
                {(props) => (
                  <RMInput
                    id="request-access-email"
                    label="Email"
                    placeholder="firstlast@email.com"
                    disabled={disabled}
                    {...props}
                  />
                )}
              </InputContainer>
            </Form>
          </RMDialog.Transition>
        </RMDialog.Body>

        <RMDialog.Footer>
          <Footer>
            <RMButton fullWidth autoLoading background="primary" onClick={onRequestAccess}>
              Request access
            </RMButton>
            <RMButton fullWidth autoLoading background="none">
              <RMText type="sans" size="xs" color="on-surface-primary" onClick={isSignedIn ? onSignOut : onSignIn}>
                {isSignedIn ? (
                  <span>
                    Not {signedInUserName}?&nbsp;<u>Log out</u>
                  </span>
                ) : (
                  <span>
                    Or&nbsp;<u>log-in</u>
                  </span>
                )}
              </RMText>
            </RMButton>
          </Footer>
        </RMDialog.Footer>
      </DialogContent>
    </RMDialog.Root>
  );
}
