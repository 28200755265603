import { styled } from '@linaria/react';

export const Wrapper = styled.div`
  padding: 0.5rem 0;
`;

export const InputBar = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: var(--border-weak);
  border-radius: var(--radius-full);
  position: relative;
`;

export const InputInnerBar = styled.div`
  height: 0.5rem;
  background-color: var(--primary);
  border-radius: var(--radius-full);
`;

export const InputRange = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;

  ::-webkit-slider-runnable-track {
    border-radius: var(--radius-full);
    background: transparent;
    height: 0.5rem;
  }

  ::-moz-range-track {
    border-radius: var(--radius-full);
    background: transparent;
    height: 0.5rem;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -0.25rem; /* Centers thumb on the track */
    background-color: var(--primary);
    height: 1rem;
    width: 1rem;
    border-radius: var(--radius-full);

    body[data-mobile='true'] & {
      margin-top: -0.5rem;
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  ::-moz-range-thumb {
    background-color: var(--primary);
    height: 1rem;
    width: 1rem;
    border-radius: var(--radius-full);
    transform: translateY(-0.375rem);
  }
`;
