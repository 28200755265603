import { css } from '@linaria/core';
import { styled } from '@linaria/react';

import { RMIconButton } from '@/components/RMIconButton/RMIconButton';

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
`;

export const ShareLinkRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: var(--spacing-lg);
`;

export const ShareLinkInput = styled.div`
  position: relative;
  width: 100%;

  > div {
    opacity: 1;
  }
`;

export const ShareLinkButton = styled(RMIconButton)`
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
`;

export const inputStyles = css`
  padding-right: 3.2rem;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-xl);
  width: 100%;

  body[data-mobile='true'] & {
    gap: var(--spacing-md);
    flex-direction: column-reverse;
    justify-content: center;
  }
`;
