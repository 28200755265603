import { styled } from '@linaria/react';

export const StoryActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);

  background: var(--surface);
  border-top: 1px solid var(--border-weak);
  padding: var(--spacing-xs) var(--spacing-md);

  body[data-mobile='false'][data-tablet='false'] & {
    border-bottom-right-radius: var(--radius-smooth);
    padding: var(--spacing-md) var(--spacing-xl);
  }

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    &[data-editing='true'] {
      border-top: none;
      border-bottom: 1px solid var(--border-weak);
    }
  }
`;

export const StoryActionsNavigation = styled.div`
  display: flex;
`;

export const ButtonsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  gap: var(--spacing-md);
`;

const EditButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-full);

  :disabled {
    background-color: var(--disabled) !important;
    box-shadow: none;

    span {
      color: var(--on-primary) !important;
    }
  }
`;

export const ConfirmButton = styled(EditButton)`
  background-color: var(--affirmative);
`;

export const CancelButton = styled(EditButton)`
  margin-left: auto;
  background-color: var(--danger);
`;

export const IconButtonsWrapper = styled.div`
  display: flex;
  gap: var(--spacing-xs);
`;

export const LastEditWrapper = styled.div``;
