import { css } from '@linaria/core';
import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const playerStyles = css`
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    background-color: var(--on-secondary);
    box-shadow: 0px 0px 0px 0px rgba(14, 23, 24, 0.38), 0px 3px 7px 0px rgba(14, 23, 24, 0.37),
      2px 13px 14px 0px rgba(14, 23, 24, 0.32), 4px 30px 18px 0px rgba(14, 23, 24, 0.19),
      8px 54px 22px 0px rgba(14, 23, 24, 0.06), 12px 84px 24px 0px rgba(14, 23, 24, 0.01);

    body[data-fullscreen='true'] & {
      background-color: var(--color-black-0);
    }
  }
`;

export const fitCover = css`
  video {
    object-fit: cover;
  }
`;

export const fitContain = css`
  video {
    object-fit: contain;
  }
`;

export const shimmerStyles = css`
  :after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(-100%);
    content: ' ';
    background: linear-gradient(90deg, #283e3e 0%, #3c5d5d 52.3%, #2b4141 100%);
    animation: shimmer 1.5s infinite ease-in-out;
    overflow: hidden;

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;

export const Content = styled.div`
  position: relative;
  max-height: 100vh;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
`;

export const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
