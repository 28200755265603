import { useMemo } from 'react';
import { EntityType } from '@remento/types/entity';
import { ReactionSentiment, ReactionStatus } from '@remento/types/reaction';
import { notNull } from '@remento/utils/array/notNull';
import { useQueries } from '@tanstack/react-query';

import { useCollection, useEntity } from '@/hooks/useQuery';
import { useServices } from '@/Services';
import { areAllQueriesLoaded } from '@/utils/query';

export function useReactionQuery(reactionId: string | null | undefined) {
  const { reactionService } = useServices();
  return useEntity(EntityType.REACTION, reactionId, (reactionId, scope) =>
    reactionService.getReaction(reactionId, scope),
  );
}

export function useReactionsQuery(recordingId: string | null | undefined) {
  const { reactionService } = useServices();
  return useCollection(EntityType.REACTION, recordingId ? { recordingId } : null, (params, scope) =>
    reactionService.getReactions(params.recordingId, scope),
  );
}

export function useReactionsSuggestionsQuery(recordingId: string | null | undefined) {
  const { reactionService } = useServices();
  return useCollection(EntityType.REACTION_SUGGESTION, recordingId ? { recordingId } : null, (params, scope) =>
    reactionService.getReactionsSuggestions(params.recordingId, scope),
  );
}

export function useReactionSuggestionBySentiment(recordingId: string | null): Record<ReactionSentiment, string> {
  const { reactionService, entityCacheManagerService } = useServices();

  const suggestionsQuery = useReactionsSuggestionsQuery(recordingId);
  const allSuggestions = useQueries({
    combine: (queries) => queries.map((q) => q.data).filter(notNull),
    queries: (suggestionsQuery.data ?? []).map((suggestionId) => {
      return entityCacheManagerService.buildEntityQuery(EntityType.REACTION_SUGGESTION, suggestionId, () =>
        reactionService.getReactionSuggestion(suggestionId),
      );
    }),
  });

  return useMemo(() => {
    const sentiments = Object.keys(ReactionSentiment) as ReactionSentiment[];
    const suggestionsBySentiment = {} as Record<ReactionSentiment, string>;

    sentiments.forEach((sentiment) => {
      const sentimentSuggestions = allSuggestions.filter(
        (suggestion) => suggestion?.message && suggestion.sentiment === sentiment,
      );
      const random = Math.floor(Math.random() * sentimentSuggestions.length);
      suggestionsBySentiment[sentiment] = sentimentSuggestions[random]?.message ?? '';
    });

    return suggestionsBySentiment;
  }, [allSuggestions]);
}

export function useSubmittedReactions(personReactionsIds: string[] | null) {
  const { entityCacheManagerService, reactionService } = useServices();

  const reactionsQueries = useQueries({
    queries: (personReactionsIds ?? []).map((reactionId) =>
      entityCacheManagerService.buildEntityQuery(EntityType.REACTION, reactionId, (_, scope) =>
        reactionService.getReaction(reactionId, scope),
      ),
    ),
  });

  return useMemo(() => {
    if (personReactionsIds !== null && areAllQueriesLoaded(reactionsQueries)) {
      const submittedReactionIds: string[] = [];
      for (const query of reactionsQueries) {
        if (query.data?.status !== ReactionStatus.SUBMITTED && query.data?.status !== ReactionStatus.SUBMITTED_AUTO) {
          continue;
        }
        submittedReactionIds.push(query.data.id);
      }

      return submittedReactionIds;
    }

    return null;
  }, [reactionsQueries, personReactionsIds]);
}
