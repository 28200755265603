import rementoBookSrc from '@/assets/remento-book.webp';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';

import {
  Image,
  LearnMoreDialogDialogBody,
  LearnMoreDialogDialogContent,
  LearnMoreDialogDialogHeader,
  RightPanel,
} from './LearnMoreDialog.styles';

export interface LearnMoreDialogProps {
  open: boolean;
  onLearnMore: () => void;
  onClose: () => void;
}

export function LearnMoreDialog({ open, onLearnMore, onClose }: LearnMoreDialogProps) {
  return (
    <RMDialog.Root open={open} variant="large" onClose={onClose}>
      <LearnMoreDialogDialogContent>
        <LearnMoreDialogDialogHeader rightAdornment={<RMCloseButton onClick={onClose} />} />
        <LearnMoreDialogDialogBody>
          <Image src={rementoBookSrc} />

          <RightPanel>
            <RMText type="sans" size="l" color="on-surface-primary" bold>
              Capture your own family's stories
            </RMText>
            <RMText type="sans" size="xs" color="on-surface-primary">
              Remento turns recording reflections about precious moments and memories into a keepsake book - no writing
              required.
            </RMText>
            <RMButton background="primary" fullWidth autoLoading onClick={onLearnMore}>
              Learn more
            </RMButton>
          </RightPanel>
        </LearnMoreDialogDialogBody>
      </LearnMoreDialogDialogContent>
    </RMDialog.Root>
  );
}
