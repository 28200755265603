import { css } from '@linaria/core';
import { styled } from '@linaria/react';

import { RMIconButton } from '@/components/RMIconButton/RMIconButton';

export const SocialButtons = styled.div`
  display: flex;
  gap: var(--spacing-md);
`;

export const SocialButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
  cursor: pointer;
`;

export const DefaultSocialButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  flex: 0 0 3.5rem;
  border-radius: var(--radius-full);
  background-color: var(--color-spruce-15);
  color: var(--secondary);

  :active {
    opacity: 0.8;
  }
`;

export const FacebookButton = styled(DefaultSocialButton)`
  background-color: hsla(221.09, 43.4%, 41.57%, 1);
  color: white;
`;

export const XTwitterButton = styled(DefaultSocialButton)`
  background-color: hsla(0, 0%, 0%, 1);
  color: white;
`;

export const WhatsappButton = styled(DefaultSocialButton)`
  background-color: hsla(142, 70%, 49%, 1);
  color: white;
`;

export const ShareLinkRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: var(--spacing-lg);
`;

export const ShareLinkInput = styled.div`
  position: relative;
  width: 100%;

  > div {
    opacity: 1;
  }
`;

export const ShareLinkButton = styled(RMIconButton)`
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
`;

export const inputStyles = css`
  padding-right: 3.2rem;
`;
