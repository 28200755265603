import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AclGroupRole } from '@remento/types/acl';
import { ConflictError, ConflictErrorType } from '@remento/types/error';
import { StoryDataType } from '@remento/types/story';
import { getStorySummary } from '@remento/utils/entity/story';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { stringToRMTextEditorContent } from '@/components/RMTextEditor/RMTextEditor';
import {
  getRMTextEditorStateText,
  getRMTextEditorText,
  RMTextEditorStore,
  setRMTextEditorValue,
} from '@/components/RMTextEditor/RMTextEditor.state';
import { toast } from '@/components/RMToast/RMToast';
import { isFormDirty, resetForm, submitForm } from '@/modules/form/form';
import { useShowPaywall } from '@/modules/paywall';
import { getSigninPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { hasRole, useCurrentUserAclRoles } from '@/services/api/acl';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook';
import { EntityMutation } from '@/services/api/cache';
import { usePersonQuery } from '@/services/api/person';
import { useStoryIssues, useStoryQuery } from '@/services/api/story';
import { captureException } from '@/utils/captureException';

import { getInputValue, setInputValues } from '../../form/input';
import { RequestAccessDialog } from '../components/RequestAccessDialog/RequestAccessDialog';
import { StoryIssuesDialog } from '../components/StoryIssuesDialog';
import { StorySummary } from '../components/StorySummary/StorySummary';
import { createRequestAccessForm } from '../forms/request-access.form';
import { StoryRegenerateForm } from '../forms/story-regenerate.form';
import { StorySummaryForm } from '../forms/story-summary.form';
import { StoriesManager } from '../states/stories.manager';
import { setStoryState, StoryManager, useStoryState } from '../states/story.manager';

import { StoryActionsContainer } from './StoryActions.container';
import { StoryRegenerateContainer } from './StoryRegenerateContainer';

export interface StorySummaryContainerProps {
  storyId: string;
  storiesManager: StoriesManager;
  storyManager: StoryManager;
  summaryForm: StorySummaryForm;
  titleTextEditor: RMTextEditorStore;
  summaryTextEditor: RMTextEditorStore;
  regenerateForm: StoryRegenerateForm;
}

const EDIT_ROLES = [AclGroupRole.OWNER, AclGroupRole.ADMIN, AclGroupRole.COLLABORATOR];

export function StorySummaryContainer({
  storyId,
  storiesManager,
  storyManager,
  summaryForm,
  titleTextEditor,
  summaryTextEditor,
  regenerateForm,
}: StorySummaryContainerProps) {
  const navigate = useNavigate();

  const {
    storyService,
    entityCacheManagerService,
    redirectService,
    projectCacheService,
    collaborationAnalyticsService,
  } = useServices();

  const signOut = useSignOut();
  const user = useUser();
  const personQuery = usePersonQuery(user?.personId);

  // Fetch the story
  const storyQuery = useStoryQuery(storyId);
  const userStoryRoles = useCurrentUserAclRoles(storyQuery.data?.acl ?? null);
  const canEdit = hasRole(EDIT_ROLES, userStoryRoles ?? []);

  const storyState = useStoryState(storyManager);

  // Request access state
  const [requestAccessDialogOpen, setRequestAccessDialogOpen] = useState(false);
  const [requestAccessSucceeded, setRequestAccessSucceeded] = useState(false);
  const requestAccessForm = useMemo(() => createRequestAccessForm(), []);

  // Paywall
  const showPaywall = useShowPaywall(storyQuery.data?.projectId);

  // Callbacks
  const handleSave = useCallback(async () => {
    if (!storyQuery.data) {
      return;
    }

    try {
      const story = storyQuery.data;
      const mutations: EntityMutation[] = [];

      if (storyState.type === 'editing') {
        setStoryState(storyManager, { ...storyState, saving: true });
      }

      const type = getInputValue(summaryForm, 'type') ?? StoryDataType.HUMAN;
      const regenerateSettings = {
        perspective: getInputValue(summaryForm, 'perspective') ?? undefined,
        length: getInputValue(summaryForm, 'length') ?? undefined,
      };

      // Update title
      const title = getRMTextEditorText(titleTextEditor) ?? '';
      mutations.push(...storyService.createSetStoryTitleMutation(story, title, type, regenerateSettings));

      // Update summary
      const summary = getRMTextEditorText(summaryTextEditor) ?? '';
      mutations.push(...storyService.createSetStorySummaryMutation(story, summary, type, regenerateSettings));

      // Run mutations
      await entityCacheManagerService.mutate(mutations);

      resetForm(summaryForm);
      setStoryState(storyManager, { type: 'view', controls: 'visible', assistant: 'hidden' });
      toast('Story updated!');
    } catch (error) {
      captureException(error, true);
      toast('Failed to update prompt', 'root-toast', 'error');

      if (storyState.type === 'editing') {
        setStoryState(storyManager, { ...storyState, saving: false });
      }
    }
  }, [
    storyQuery.data,
    storyState,
    summaryForm,
    storyService,
    entityCacheManagerService,
    storyManager,
    titleTextEditor,
    summaryTextEditor,
  ]);

  const handleConfirmDiscard = useCallback(() => {
    setRMTextEditorValue(summaryTextEditor, stringToRMTextEditorContent(getStorySummary(storyQuery.data)));
    resetForm(summaryForm);
    setStoryState(storyManager, { type: 'view', controls: 'visible', assistant: 'hidden' });
  }, [summaryForm, storyManager, summaryTextEditor, storyQuery.data]);

  const handleDiscard = useCallback(() => {
    // Check if the form is dirty and show the 'confirm-cancel' dialog if it is dirty
    if (!isFormDirty(summaryForm)) {
      handleConfirmDiscard();
    } else {
      setStoryState(storyManager, { type: 'discard-save' });
    }
  }, [storyManager, summaryForm, handleConfirmDiscard]);

  const handleCancelDiscard = useCallback(() => {
    // Check if the form is dirty and show the 'confirm-cancel' dialog if it is dirty
    setStoryState(storyManager, { type: 'editing', assistant: 'hidden' });
  }, [storyManager]);

  const handleOpenRegenerate = useCallback(async () => {
    if (showPaywall()) {
      return;
    }

    if (canEdit == false) {
      setRequestAccessDialogOpen(true);
      return;
    }

    if (storyState.type === 'view') {
      setStoryState(storyManager, { type: 'view', assistant: 'visible', controls: storyState.controls });
      return;
    }
    setStoryState(storyManager, { type: 'editing', assistant: 'visible' });
  }, [showPaywall, canEdit, storyState, storyManager]);

  const handleEditSummary = useCallback(async () => {
    if (showPaywall()) {
      return;
    }

    if (canEdit) {
      setStoryState(storyManager, { type: 'editing', assistant: 'hidden' });
      return;
    }

    setRequestAccessDialogOpen(true);
  }, [canEdit, showPaywall, storyManager]);

  const state = useMemo(() => {
    if ((storyState.type === 'editing' || storyState.type === 'view') && storyState.assistant !== 'hidden') {
      return 'regenerating';
    }

    if (storyState.type === 'editing') {
      return 'editing';
    }

    return 'viewing';
  }, [storyState]);

  // Request access callbacks
  const handleRequestAccess = useCallback(async () => {
    if (storyQuery.data == null) {
      return;
    }

    await submitForm(requestAccessForm, async (data) => {
      try {
        await projectCacheService.requestAccess(storyQuery.data.projectId, data);
        setRequestAccessSucceeded(true);
        setTimeout(() => {
          setRequestAccessDialogOpen(false);
          setRequestAccessSucceeded(false);
        }, 2500);
        collaborationAnalyticsService.onAccessRequested('story-standalone', 'edit-story');
      } catch (error) {
        if (error instanceof ConflictError && error.data?.type == ConflictErrorType.USER_ALREADY_EXISTS) {
          toast('A user already exists with this email. Please sign in.', 'root-toast', 'error');
          return;
        }
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    });
  }, [collaborationAnalyticsService, projectCacheService, requestAccessForm, storyQuery.data]);

  const handleSignIn = useCallback(async () => {
    await redirectService.registerRedirect('signed-in', window.location.pathname + window.location.search);
    navigate(getSigninPath({ backupLocalData: true }));
  }, [navigate, redirectService]);

  const handleSignOut = useCallback(async () => {
    await redirectService.registerRedirect('signed-out', window.location.pathname + window.location.search);
    await signOut();
  }, [redirectService, signOut]);

  const handleCloseRequestAccessDialog = useCallback(() => {
    setRequestAccessDialogOpen(false);
  }, []);

  // Issues state
  const [issuesDialogOpen, setIssuesDialogOpen] = useState(false);
  const issues = useStoryIssues(storiesManager.showIssues ? storyId : null);

  // Learn more state
  const handleLearnMore = useCallback(() => {
    window.open('https://remento.co', '_blank');
  }, []);

  useEffect(() => {
    if (user != null && personQuery.data != null) {
      setInputValues(requestAccessForm, {
        firstName: '',
        lastName: '',
        email: '',
      });
    }

    setInputValues(requestAccessForm, {
      firstName: personQuery.data?.name?.first,
      lastName: personQuery.data?.name?.last,
      email: user?.communicationChannels.email,
    });
  }, [personQuery.data, requestAccessForm, user]);

  // Removes line breaks from the title
  useEffect(() => {
    return titleTextEditor.subscribe((state) => {
      const titleText = getRMTextEditorStateText(state);
      if (titleText.includes('\n')) {
        setRMTextEditorValue(titleTextEditor, stringToRMTextEditorContent(titleText.replaceAll('\n', '')));
      }
    });
  }, [titleTextEditor]);

  const StoryRegenerate = useMemo(
    () => (
      <StoryRegenerateContainer
        storyId={storyId}
        storyManager={storyManager}
        summaryForm={summaryForm}
        titleTextEditor={titleTextEditor}
        summaryTextEditor={summaryTextEditor}
        regenerateForm={regenerateForm}
        onSave={handleSave}
      />
    ),
    [handleSave, regenerateForm, storyId, storyManager, summaryForm, titleTextEditor, summaryTextEditor],
  );

  return (
    <>
      <StorySummary
        form={summaryForm}
        titleTextEditor={titleTextEditor}
        summaryTextEditor={summaryTextEditor}
        state={state}
        saving={storyState.type === 'editing' && storyState.saving === true}
        showLearnMore={user == null}
        issues={issues?.map((i) => i.type) ?? null}
        onViewIssues={() => setIssuesDialogOpen(true)}
        onLearnMore={handleLearnMore}
        StoryActions={
          <StoryActionsContainer
            form={summaryForm}
            storiesManager={storiesManager}
            storyManager={storyManager}
            onOpenRegenerate={handleOpenRegenerate}
            onEditSummary={handleEditSummary}
            onEditCancel={handleDiscard}
            onSave={handleSave}
          />
        }
        StoryRegenerate={StoryRegenerate}
      />

      <StoryIssuesDialog
        open={issuesDialogOpen}
        issues={(issues ?? []).map((i) => i.type)}
        onClose={() => setIssuesDialogOpen(false)}
      />

      <RequestAccessDialog
        open={requestAccessDialogOpen}
        form={requestAccessForm}
        disabled={user != null}
        isSignedIn={user != null}
        signedInUserName={personQuery.data?.name?.first ?? null}
        success={requestAccessSucceeded}
        onRequestAccess={handleRequestAccess}
        onSignIn={handleSignIn}
        onSignOut={handleSignOut}
        onClose={handleCloseRequestAccessDialog}
      />

      <RMConfirmationModal
        open={storyState.type === 'discard-save'}
        type="primary"
        title="Save changes?"
        message="You have unsaved changes on this page. Would you like to save them?"
        confirmLabel="Save changes"
        cancelLabel="Discard"
        onCancel={handleConfirmDiscard}
        onConfirm={handleSave}
        onClose={handleCancelDiscard}
      />
    </>
  );
}
