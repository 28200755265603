import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMImage } from '@/components/RMImage/RMImage';
import { RMText } from '@/components/RMText/RMText';

export const StyledStoryPlayer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: visible;

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    justify-content: flex-start;
    background-color: var(--surface);
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  // width for video (maxContent - summaryContent)
  // next/prev btn padding = 12rem
  // Use the min value from MaxVideo content vs Screen size
  min-width: calc(min(calc(93.75rem - 32rem), calc(100vw - 12rem)));
  position: relative;

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    min-width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    overflow: auto;
    height: 100%;
  }

  body[data-fullscreen='true'] & {
    width: 100%;
  }
`;

export const Image = styled(RMImage)`
  max-height: 100%;
  max-width: 100%;
  width: 100% !important;
  height: 100%;
  object-fit: contain;
  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    border-radius: 0;
  }

  &[data-primary-media='false'] {
    object-fit: cover;
  }
`;

export const MediaContainer = styled.div`
  // max width for video (maxContent - summaryContent)
  width: calc(93.75rem - 32rem);
  // next/prev btn padding = 12rem
  max-width: calc(100vw - 32rem - 12rem);
  display: flex;
  justify-content: center;
  align-items: center;

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    max-width: unset;
    height: 100%;
    width: auto;
    pointer-events: auto;
    object-fit: contain;

    &[data-type='audio'] {
      max-width: 7.5rem;
    }
  }

  body[data-fullscreen='true'] & {
    max-width: unset !important;
    width: 100%;
  }

  body[data-fullscreen='true'] &[data-type='audio'] {
    width: 100%;
    height: 100%;
  }
`;

export const MediaWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-smooth) 0 0 var(--radius-smooth);
  border: 1px solid var(--inverse-border-weak);
  border-right: none;
  background-color: var(--surface-always-dark);
  overflow: hidden;
  position: relative;

  body[data-mobile='true'] & {
    max-width: 100vw;
    height: 17.3125rem;
    flex: 0 0 17.3125rem;
    border-radius: 0;
    border: none;
  }

  body[data-tablet='true'] & {
    max-width: 100vw;
    width: 100vw;
    height: 66vw;
    flex: 0 0 66vw;
    border-radius: 0;
    border: none;
  }

  body[data-tablet='true'][data-fullscreen='true'] &,
  body[data-mobile='true'][data-fullscreen='true'] & {
    height: 100%;
    flex: 1;
  }

  body[data-fullscreen='true'] & {
    background-color: black;
    border-radius: 0;
    border: none;
  }
`;

export const AbsoluteMediaContainer = styled(motion.div)`
  width: calc(100%);
  position: absolute;
  object-fit: cover;
  z-index: 1;
  bottom: 0;
  left: 0;
  padding: var(--spacing-md) var(--spacing-xl);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(20, 31, 31, 0) 100%);

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    pointer-events: none;
    gap: var(--spacing-sm);
    padding: var(--spacing-md);
  }
`;

export const PipWrapper = styled.div`
  position: relative;
  width: 11.25rem;
  height: 11.25rem;
  border-radius: var(--radius-smooth);
  overflow: hidden;
  box-shadow: 0px 0px 0px 0px #0e171861, 0px 3px 7px 0px #0e17185e, 2px 13px 14px 0px #0e171852,
    4px 30px 18px 0px #0e171830, 8px 54px 22px 0px #0e17180f, 12px 84px 24px 0px #0e171803;
  transition: width 300ms ease, height 300ms ease;

  body[data-mobile='false'][data-fullscreen='true'] & {
    width: 22.5rem;
    height: 22.5rem;
  }

  body[data-mobile='true'][data-fullscreen='false'] & {
    width: 6rem;
    height: 6rem;
  }

  body[data-tablet='true'][data-fullscreen='false'] & {
    width: 8.5rem;
    height: 8.5rem;
  }

  body[data-tablet='true'][data-fullscreen='true'] &,
  body[data-mobile='true'][data-fullscreen='true'] & {
    width: 9.25rem;
    height: 9.25rem;
  }
`;

export const MaximizeButton = styled(RMIconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
  pointer-events: auto;
`;

export const OutPortalWrapper = styled.div`
  /* This will prevent bubbling event issues using the reverse portal library */
  pointer-events: none;
  height: 100%;
  width: 100%;
  background-color: var(--color-spruce-10);

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
`;

export const BackgroundImage = styled(motion.img)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(1rem);
  scale: 1.2;
  opacity: 0.1;

  body[data-fullscreen='true'] & {
    display: none;
  }
`;

export const TopOverlay = styled.div`
  width: calc(100% - 32rem);
  position: absolute;
  // prevent being over the border
  top: 1px;
  left: 0;
  z-index: 1;
  border-radius: var(--radius-smooth) 0 0 var(--radius-smooth);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(20, 31, 31, 0) 100%);
  padding: var(--spacing-xl);
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 300ms ease;

  &[data-showing='true'] {
    opacity: 1;
  }

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    width: 100%;
    height: 5rem;
    top: 0;
    border-radius: 0;
  }

  body[data-fullscreen='true'] & {
    width: 100%;
    border-radius: 0;
    height: unset;
    min-height: 5rem;
  }
`;

export const HighlightsReelButton = styled.div`
  cursor: pointer;
  display: flex;
  gap: var(--spacing-xs);
`;

export const MobileHighlightsReelButtonWrapper = styled.div`
  position: absolute;
  right: var(--spacing-md);
  top: var(--spacing-md);
  height: 2.5rem;
  display: flex;
  align-items: center;
  z-index: 1;
  transition: all 500ms ease;

  opacity: 0;
  &[data-showing='true'] {
    opacity: 1;
  }

  body[data-fullscreen='true'] & {
    display: none;
  }
`;

export const FullscreenStoryTitle = styled(RMText)`
  padding-right: var(--spacing-3xl);
`;

export const FullscreenCloseButton = styled(RMIconButton)`
  position: absolute;
  right: var(--spacing-sm);
  top: var(--spacing-xl);

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    top: var(--spacing-lg);
  }
`;
