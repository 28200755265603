import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal';
import { faSparkles, faUpRightAndDownLeftFromCenter, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { easeInOut } from 'framer-motion';

import { RMText } from '@/components/RMText/RMText';
import { useIsFullscreen } from '@/hooks/useIsFullscreen';
import { useIsMobileOrTabletViewport, useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { PlayerScrubber } from '@/modules/media-player/PlayerScrubber';

import { StoryAudioPlayer } from '../StoryAudioPlayer/StoryAudioPlayer';
import { StoryVideoPlayer } from '../StoryVideoPlayer/StoryVideoPlayer';

import {
  AbsoluteMediaContainer,
  BackgroundImage,
  Content,
  FullscreenCloseButton,
  FullscreenStoryTitle,
  HighlightsReelButton,
  Image,
  MaximizeButton,
  MediaContainer,
  MediaWrapper,
  MobileHighlightsReelButtonWrapper,
  OutPortalWrapper,
  PipWrapper,
  StyledStoryPlayer,
  TopOverlay,
} from './StoryPlayer.styles';
import { StoryPlayerPlayButton } from './StoryPlayerPlayButton';

interface StoryPlayerProps {
  type: 'audio' | 'video';
  primaryMedia: 'video' | 'photo';
  imageUrl: string | null;
  authorInitials: string;
  authorAvatarUrl: string | null;
  controlsVisible: boolean;
  StorySummary: ReactNode;
  promptText: string;
  recordedDate?: string;
  title?: string;
  editing: boolean;
  onChangePrimaryMedia: (media: 'video' | 'photo') => void;
  onMouseOver: (hovering: boolean) => void;
  onToggleControlsVisibility: () => void;
  onExitFullscreen: () => void;
  onViewHighlightReel?: () => void;
}

// We need to use framer to do this because in iOS safari, animating a transform animation
// can cause flickering in some cases.
function getMediaContainerAnimation(isMobileOrTablet: boolean, controlsVisible: boolean) {
  if (isMobileOrTablet) {
    return {
      y: `${controlsVisible ? 0 : 3.5}rem`,
    };
  }
  return {
    y: `${controlsVisible ? 0 : 6}rem`,
  };
}

export function StoryPlayer({
  type,
  primaryMedia,
  imageUrl,
  authorInitials,
  authorAvatarUrl,
  controlsVisible,
  StorySummary,
  promptText,
  recordedDate,
  title,
  editing,
  onChangePrimaryMedia,
  onToggleControlsVisibility,
  onMouseOver,
  onExitFullscreen,
  onViewHighlightReel,
}: StoryPlayerProps) {
  const isMobileOrTablet = useIsMobileOrTabletViewport();
  const isMobile = useIsMobileViewport();
  const isFullscreen = useIsFullscreen();

  const playerPortalNode = useMemo(() => createHtmlPortalNode(), []);

  const initialsSize = useMemo(() => {
    if (!imageUrl) {
      return 'large';
    }

    return isMobile ? 'small' : 'medium';
  }, [isMobile, imageUrl]);

  // Scroll down to the start of the title input when entering edit mode on mobile
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (editing && isMobileOrTablet) {
      // video height - actions height
      if (scrollRef.current != null) {
        scrollRef.current.scrollTop = 277 - 65;
      }
    }
  }, [editing, isMobileOrTablet]);

  return (
    <StyledStoryPlayer>
      {/* We should render the player portal as soon as possible.
      The component will not actually will not be mounted to the dom yet.*/}
      <InPortal node={playerPortalNode}>
        {type === 'video' ? (
          <StoryVideoPlayer key="video" fitMode={isFullscreen ? 'contain' : 'cover'} />
        ) : (
          <StoryAudioPlayer
            authorInitials={authorInitials}
            authorAvatarUrl={authorAvatarUrl}
            size={initialsSize}
            box={imageUrl !== null}
          />
        )}
      </InPortal>

      <Content ref={scrollRef}>
        <TopOverlay
          onMouseOut={() => onMouseOver(false)}
          onMouseMove={() => onMouseOver(true)}
          data-showing={controlsVisible}
        >
          {isFullscreen && (
            <FullscreenStoryTitle type="serif" size={isMobileOrTablet ? 'm' : 'xxl'} color="inverse-on-surface-primary">
              {title}
            </FullscreenStoryTitle>
          )}
          {isFullscreen && (
            <FullscreenCloseButton
              icon={faXmark}
              tooltip={{ label: 'Close', position: 'bottom' }}
              color="white"
              backgroundColor="transparent"
              onClick={onExitFullscreen}
            />
          )}
          {isMobileOrTablet == false && isFullscreen == false && (
            <>
              {recordedDate && (
                <RMText type="sans" size="xxs" bold color="inverse-on-surface-primary">
                  Recorded {recordedDate}
                </RMText>
              )}
            </>
          )}
          {onViewHighlightReel && isMobileOrTablet == false && isFullscreen == false && (
            <HighlightsReelButton onClick={onViewHighlightReel}>
              <FontAwesomeIcon size="xs" icon={faSparkles} color="var(--secondary)" />
              <RMText type="sans" size="xs" bold color="secondary">
                View highlights
              </RMText>
            </HighlightsReelButton>
          )}
          {onViewHighlightReel && isMobileOrTablet && isFullscreen == false && (
            <MobileHighlightsReelButtonWrapper data-showing={controlsVisible}>
              <HighlightsReelButton onClick={onViewHighlightReel}>
                <FontAwesomeIcon size="xs" icon={faSparkles} color="var(--secondary)" />
                <RMText type="sans" size="xs" bold color="secondary">
                  View highlights
                </RMText>
              </HighlightsReelButton>
            </MobileHighlightsReelButtonWrapper>
          )}
        </TopOverlay>

        <MediaWrapper onMouseOut={() => onMouseOver(false)} onMouseMove={() => onMouseOver(true)}>
          <BackgroundImage
            key={imageUrl}
            src={imageUrl ?? undefined}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.1 }}
            exit={{ opacity: 0 }}
            transition={{ type: easeInOut(0.6) }}
            onClick={onToggleControlsVisibility}
          />

          {isMobile && controlsVisible && <StoryPlayerPlayButton />}

          {(imageUrl == null || primaryMedia == 'video') && (
            <MediaContainer data-type={type} onClick={onToggleControlsVisibility}>
              <OutPortalWrapper>
                <OutPortal node={playerPortalNode} />
              </OutPortalWrapper>
            </MediaContainer>
          )}
          {imageUrl != null && primaryMedia == 'photo' && (
            <Image data-primary-media={true} src={imageUrl} onClick={onToggleControlsVisibility} />
          )}

          <AbsoluteMediaContainer
            onMouseOut={() => onMouseOver(false)}
            onMouseMove={() => onMouseOver(true)}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
            animate={getMediaContainerAnimation(isMobile, controlsVisible)}
          >
            {imageUrl != null && (
              <PipWrapper onClick={onToggleControlsVisibility}>
                {type == 'video' && (
                  <MaximizeButton
                    tooltip={null}
                    icon={faUpRightAndDownLeftFromCenter}
                    backgroundColor="transparent"
                    color="white"
                    size={isMobileOrTablet ? 'sm' : 'lg'}
                    onClick={(e) => {
                      onChangePrimaryMedia(primaryMedia == 'photo' ? 'video' : 'photo');
                      // This will prevent the click from toggling the controls visibility
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  />
                )}
                {primaryMedia == 'photo' ? (
                  <OutPortalWrapper>
                    <OutPortal node={playerPortalNode} />
                  </OutPortalWrapper>
                ) : (
                  <Image data-primary-media={false} src={imageUrl} onClick={onToggleControlsVisibility} />
                )}
              </PipWrapper>
            )}
            <PlayerScrubber promptText={promptText} hideThumb={isMobileOrTablet} />
          </AbsoluteMediaContainer>
        </MediaWrapper>
        {StorySummary}
      </Content>
    </StyledStoryPlayer>
  );
}
