import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { LottieAnimation } from '@/components/LottieAnimation';
import { RMDialog } from '@/components/RMDialog';

export const DialogContent = styled(RMDialog.Content)`
  position: relative;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--spacing-sm);
`;

export const Form = styled.div`
  padding-top: var(--spacing-lg);
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: var(--spacing-lg) var(--spacing-md);

  > *:nth-child(1),
  > *:nth-child(2) {
    grid-column: auto;
  }

  > *:nth-child(3) {
    grid-column: 1 / span 2;
  }

  body[data-mobile='true'] & {
    > *:nth-child(1),
    > *:nth-child(2) {
      grid-column: 1 / span 2;
    }
  }
`;

export const SuccessAnimationContainer = styled(motion.div)`
  background-color: var(--surface);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LottieCheckMark = styled(LottieAnimation)`
  position: relative;
  width: 5rem;
  height: 5rem;
  flex: 0 0 5rem;
`;

export const SuccessTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-xs);
  max-width: 237px;
`;
