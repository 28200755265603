import { useCallback, useEffect, useMemo } from 'react';

import { useSearchParams } from '@/hooks/useSearchParams';
import { watchInputValue } from '@/modules/form/input';

import { StoryReactionForm } from '../forms/story-reaction.form';

export const REACTION_MESSAGE_PARAM_NAME = 'reactionMessage';
export const REACTION_SENTIMENT_PARAM_NAME = 'reactionSentiment';

export function useSyncStoryReactionForm(reactionForm: StoryReactionForm) {
  const { setSearchParam, deleteSearchParam } = useSearchParams();

  useEffect(() => {
    const unsubscribeMessageChange = watchInputValue(reactionForm, 'message', (message) => {
      setSearchParam(REACTION_MESSAGE_PARAM_NAME, message, { replace: true });
    });
    const unsubscribeSentimentChange = watchInputValue(reactionForm, 'sentiment', (sentiment) => {
      setSearchParam(REACTION_SENTIMENT_PARAM_NAME, sentiment ?? null, { replace: true });
    });

    return () => {
      unsubscribeMessageChange();
      unsubscribeSentimentChange();
    };
  }, [reactionForm, setSearchParam]);

  const clearStoryReactionFormParams = useCallback(() => {
    deleteSearchParam(REACTION_MESSAGE_PARAM_NAME, { replace: true });
    deleteSearchParam(REACTION_SENTIMENT_PARAM_NAME, { replace: true });
  }, [deleteSearchParam]);

  return useMemo(() => ({ clearStoryReactionFormParams }), [clearStoryReactionFormParams]);
}
