import { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

import { Content } from './StoryView.styles';

type StoryViewProps = PropsWithChildren<{
  activeStory: boolean;
  animationDir: 'ltr' | 'rtl' | null;
}>;

export function StoryView({ activeStory, animationDir, children }: StoryViewProps) {
  const startX = animationDir === 'ltr' ? '-100%' : '100%';
  const exitX = animationDir === 'ltr' ? '100%' : '-100%';
  const animateFrom = activeStory ? { x: startX, opacity: 0 } : { x: 0, opacity: 1 };
  const animateTo = activeStory ? { x: 0, opacity: 1 } : { x: exitX, opacity: 0 };

  return (
    <Content data-visible={activeStory} data-interactable={activeStory}>
      <motion.div
        key={`${activeStory}`}
        initial={animationDir ? animateFrom : { x: 0, opacity: 0 }}
        animate={animationDir ? animateTo : { x: 0, opacity: activeStory ? 1 : 0 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        style={{ height: '100%' }}
      >
        {children}
      </motion.div>
    </Content>
  );
}
