import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  StoryDataType,
  StoryLengthType,
  StoryPerspectiveType,
  SUMMARY_STREAM_DATA_SEPARATOR,
} from '@remento/types/story';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { stringToRMTextEditorContent } from '@/components/RMTextEditor/RMTextEditor';
import {
  createRMTextEditorStore,
  getRMTextEditorText,
  RMTextEditorStore,
  setRMTextEditorValue,
  useRMTextEditorValue,
} from '@/components/RMTextEditor/RMTextEditor.state';
import { submitForm } from '@/modules/form/form';
import { setInputValue, useInputValue } from '@/modules/form/input';
import { useServices } from '@/Services';
import { usePersonQuery } from '@/services/api/person';
import { useRecordingQuery } from '@/services/api/recording';
import { useStoryQuery } from '@/services/api/story';

import { StoryRegenerate } from '../components/StoryRegenerate/StoryRegenerate';
import { StoryRegeneratePreview } from '../components/StoryRegeneratePreview/StoryRegeneratePreview';
import { StoryRegenerateForm } from '../forms/story-regenerate.form';
import { StorySummaryForm } from '../forms/story-summary.form';
import { setStoryState, StoryManager, useStoryState } from '../states/story.manager';

interface StoryRegenerateContainerProps {
  storyId: string;
  storyManager: StoryManager;
  regenerateForm: StoryRegenerateForm;
  summaryForm: StorySummaryForm;
  titleTextEditor: RMTextEditorStore;
  summaryTextEditor: RMTextEditorStore;
  onSave: () => Promise<void>;
}

export function StoryRegenerateContainer({
  storyId,
  storyManager,
  regenerateForm,
  summaryForm,
  titleTextEditor,
  summaryTextEditor,
  onSave,
}: StoryRegenerateContainerProps) {
  const { storyService } = useServices();
  const storyQuery = useStoryQuery(storyId);

  const previewTitleTextEditorStore = useMemo(() => createRMTextEditorStore(), []);
  const previewTitle = useRMTextEditorValue(previewTitleTextEditorStore);
  const previewTextEditorStore = useMemo(() => createRMTextEditorStore(), []);
  const previewSummary = useRMTextEditorValue(previewTextEditorStore);
  const [previewSettings, setPreviewSettings] = useState<{
    perspective?: StoryPerspectiveType;
    length?: StoryLengthType;
  }>({});
  const [finished, setFinished] = useState(false);
  const regenerateAbortControllerRef = useRef<AbortController | null>(null);

  const storyState = useStoryState(storyManager);
  const regeneratePerspective = useInputValue(regenerateForm, 'perspective');

  const recordingQuery = useRecordingQuery(storyQuery.data?.recordingsIds[0]);
  const storytellerPersonQuery = usePersonQuery(recordingQuery.data?.personId ?? null);
  const storytellerName = storytellerPersonQuery.data?.name?.first || '';

  const handleGeneratePreview = useCallback(async () => {
    await submitForm(regenerateForm, async (payload) => {
      switch (storyState.type) {
        case 'view':
          setStoryState(storyManager, { type: 'view', assistant: 'preview', controls: storyState.controls });
          break;
        case 'editing':
          setStoryState(storyManager, { type: 'editing', assistant: 'preview' });
          break;
      }

      setRMTextEditorValue(previewTextEditorStore, null);
      setRMTextEditorValue(previewTitleTextEditorStore, null);
      setFinished(false);

      // Transcript will use LONG length
      if (payload.perspective === StoryPerspectiveType.TRANSCRIPT) {
        payload.length = StoryLengthType.LONG;
      }

      setPreviewSettings({
        perspective: payload.perspective,
        length: payload.length,
      });

      regenerateAbortControllerRef.current = new AbortController();

      const generatedTitleData = {
        numberOfSequentialSeparatorCharacters: 0,
        title: '',
        done: false,
      };
      await storyService.generateStorySummary(
        storyId,
        payload,
        (textChunk: string) => {
          let summaryChunk = '';

          // The result from storyService.generateStorySummary is the title, followed by a separator and then
          // the summary. This logic makes sure we are parsing that correctly.

          // If it's done, we are already in the summary
          if (!generatedTitleData.done) {
            for (const char of textChunk) {
              // It could be part title, separator and part summary
              if (!generatedTitleData.done) {
                if (char === SUMMARY_STREAM_DATA_SEPARATOR[generatedTitleData.numberOfSequentialSeparatorCharacters]) {
                  generatedTitleData.numberOfSequentialSeparatorCharacters += 1;
                } else {
                  generatedTitleData.title += char;
                  generatedTitleData.numberOfSequentialSeparatorCharacters = 0;
                }

                if (generatedTitleData.numberOfSequentialSeparatorCharacters === SUMMARY_STREAM_DATA_SEPARATOR.length) {
                  generatedTitleData.done = true;
                  // Not breaking in case the text chunk contains part title, separator and part summary
                }
              } else {
                // Trimming the eventual line breaks/spaces
                if (!char.match(/\s/)) {
                  summaryChunk += char;
                }
              }
            }
          } else {
            summaryChunk = textChunk;
          }

          setRMTextEditorValue(
            previewTitleTextEditorStore,
            stringToRMTextEditorContent(generatedTitleData.title.trim()),
          );
          setRMTextEditorValue(
            previewTextEditorStore,
            stringToRMTextEditorContent(
              `${getRMTextEditorText(previewTextEditorStore)}${summaryChunk}`.replaceAll('\n\n', '\n'),
            ),
          );
        },
        regenerateAbortControllerRef.current.signal,
      );
      setFinished(true);
    });
  }, [
    regenerateForm,
    storyId,
    storyManager,
    storyService,
    storyState,
    previewTextEditorStore,
    previewTitleTextEditorStore,
  ]);

  const handleRegenerateCancel = useCallback(() => {
    if (storyState.type === 'view') {
      setStoryState(storyManager, { type: 'view', assistant: 'hidden', controls: storyState.controls });
      return;
    }
    setStoryState(storyManager, { type: 'editing', assistant: 'hidden' });
  }, [storyManager, storyState]);

  const handleRegeneratePreviewCancel = useCallback(() => {
    switch (storyState.type) {
      case 'view':
        setStoryState(storyManager, { type: 'view', assistant: 'visible', controls: storyState.controls });
        break;
      case 'editing':
        setStoryState(storyManager, { type: 'editing', assistant: 'visible' });
        break;
    }

    regenerateAbortControllerRef.current?.abort();
    regenerateAbortControllerRef.current = null;
  }, [storyManager, storyState]);

  const handleRegeneratePreviewConfirmOpen = useCallback(() => {
    switch (storyState.type) {
      case 'view':
        setStoryState(storyManager, { type: 'view', assistant: 'confirm-update', controls: storyState.controls });
        break;
      case 'editing':
        setStoryState(storyManager, { type: 'editing', assistant: 'confirm-update' });
        break;
    }
  }, [storyManager, storyState]);

  const handleRegeneratePreviewConfirmSave = useCallback(() => {
    setRMTextEditorValue(titleTextEditor, previewTitle);
    setRMTextEditorValue(summaryTextEditor, previewSummary);
    setInputValue(summaryForm, 'perspective', previewSettings.perspective);
    setInputValue(summaryForm, 'length', previewSettings.length);
    setInputValue(summaryForm, 'type', StoryDataType.AI);
    return onSave();
  }, [summaryForm, onSave, previewTitle, previewSettings, titleTextEditor, summaryTextEditor, previewSummary]);

  const handleRegeneratePreviewConfirmClose = useCallback(() => {
    setStoryState(storyManager, { type: 'editing', assistant: 'preview' });
  }, [storyManager]);

  const handleOpenFaq = useCallback(() => {
    window.open('https://help.remento.co/en/articles/8365905-can-i-change-how-remento-writes-my-story', '_blank');
  }, []);

  const previewOpen =
    (storyState.type === 'editing' || storyState.type === 'view') &&
    (storyState.assistant === 'preview' || storyState.assistant === 'confirm-update');
  const confirmUpdateOpen =
    (storyState.type === 'editing' || storyState.type === 'view') && storyState.assistant === 'confirm-update';

  useEffect(() => {
    return () => {
      regenerateAbortControllerRef.current?.abort();
      regenerateAbortControllerRef.current = null;
    };
  }, []);

  return (
    <>
      <StoryRegenerate
        form={regenerateForm}
        storytellerName={storytellerName}
        hideLength={regeneratePerspective === StoryPerspectiveType.TRANSCRIPT}
        onOpenFaq={handleOpenFaq}
        onGeneratePreview={handleGeneratePreview}
        onRegenerateCancel={handleRegenerateCancel}
      />

      {previewOpen && (
        <StoryRegeneratePreview
          title={previewTitle}
          previewSummary={previewSummary}
          canUpdate={finished}
          onRegeneratePreviewCancel={handleRegeneratePreviewCancel}
          onRegeneratePreviewConfirm={handleRegeneratePreviewConfirmOpen}
        />
      )}

      <RMConfirmationModal
        open={confirmUpdateOpen}
        type="primary"
        title="Update story?"
        message="By confirming, you'll overwrite all existing edits to the written story."
        confirmLabel="Yes, update"
        cancelLabel="Cancel"
        onCancel={handleRegeneratePreviewCancel}
        onConfirm={handleRegeneratePreviewConfirmSave}
        onClose={handleRegeneratePreviewConfirmClose}
      />
    </>
  );
}
