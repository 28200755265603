import { useRef } from 'react';
import { MediaPlayer, MediaPlayerInstance, MediaPlayerProps, useMediaRemote } from '@vidstack/react';
import clsx from 'clsx';

import { useVideoManager } from '../stories/states/video.manager';

import { mediaPlayerStyles } from './PlayerContext.styles';

export type PlayerContextProps = MediaPlayerProps;

export function PlayerContext({ children, className, ...props }: PlayerContextProps) {
  const remote = useMediaRemote();
  const videoManager = useVideoManager(remote);

  const playerRef = useRef<MediaPlayerInstance | null>();

  return (
    <MediaPlayer
      className={clsx(mediaPlayerStyles, className)}
      {...props}
      ref={(ref) => (playerRef.current = ref)}
      onCanPlay={(_1, _2) => {
        if (props.autoplay) {
          playerRef.current?.play().catch(() => console.log('Failed to auto play'));
        }

        props.onCanPlay?.(_1, _2);
      }}
      onLoadedMetadata={(event) => {
        remote.setTarget(event.target);
      }}
      onPlay={(event) => {
        videoManager?.pauseOthers(remote);
        props.onPlay?.(event);
      }}
    >
      {children}
    </MediaPlayer>
  );
}
