import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';

export const StyledStoryRegenerate = styled(motion.div)`
  position: absolute;
  width: 100%;
  bottom: 0;
  background: var(--surface);
  box-shadow: var(--elevation-top-3);
  /* The z-index should be the same as the RMDialog */
  z-index: 999;

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    border-radius: var(--radius-smooth) var(--radius-smooth) 0px 0px;
    position: fixed;
  }
`;

export const ScrimOverlay = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--scrim-weak);
  /* The z-index should be the same as the RMDialog */
  z-index: 999;

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    position: fixed;
    top: 0;
  }
`;

export const Body = styled.div`
  padding: var(--spacing-xl) var(--spacing-xl) 2.875rem var(--spacing-xl);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    padding-left: var(--spacing-md);
    padding-right: var(--spacing-md);
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);
`;

export const HeaderButtons = styled.div`
  position: absolute;
  right: var(--spacing-2xs);
  top: var(--spacing-sm);
  display: flex;
  gap: var(--spacing-sm);
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  transition: opacity 200ms ease;

  &[data-hide='true'] {
    opacity: 0;
    pointer-events: none;
  }
`;

export const FixedFormItem = styled(FormItem)`
  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    min-height: 10rem;
  }
`;

export const FormItemLabel = styled(RMText)`
  letter-spacing: 0.075rem;
`;

export const PerspectiveOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-md);
  margin-bottom: var(--spacing-sm);
  gap: var(--spacing-md);

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    height: 4.5rem;
  }
`;

export const PerspectiveButton = styled(RMButton)`
  width: 33.33%;
  box-sizing: border-box;
  border: 1px solid var(--border-weak);
  border-radius: var(--radius-round);
  line-height: 1.225rem;
  padding: var(--spacing-md) var(--spacing-sm);
  height: auto;

  &[data-active='true'] {
    border-width: 2px;
    border-color: var(--border-primary);
    box-shadow: var(--elevation-bottom-2);

    &:hover:after {
      display: none;
    }
  }

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    min-width: auto;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);
`;

export const Footer = styled.div`
  background: var(--surface);
  border-top: 1px solid var(--border-weak);
  padding: var(--spacing-md) var(--spacing-xl);
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    width: 100%;
    justify-content: center;

    > button {
      flex: 1;
    }
  }
`;

export const InputRangeWrapper = styled.div`
  width: 100%;
  padding: var(--spacing-sm) 0;
`;

export const LengthDescribe = styled.div`
  display: flex;
  justify-content: space-between;
`;
