import { MouseEvent } from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import { RMText } from '../RMText/RMText';
import { RMTooltip } from '../RMTooltip/RMTooltip';

import { PrimaryButton, SecondaryButton, SecondaryIcon, SplitButtonContainer } from './RMSplitButton.styles';

export interface RMSplitButtonProps {
  primaryLabel: string;
  secondaryIcon: IconDefinition;
  secondaryTooltip: string;
  onPrimaryClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onSecondaryClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export function RMSplitButton({
  primaryLabel,
  secondaryIcon,
  secondaryTooltip,
  onPrimaryClick,
  onSecondaryClick,
}: RMSplitButtonProps) {
  return (
    <SplitButtonContainer>
      <PrimaryButton onClick={onPrimaryClick}>
        <RMText type="sans" size="xs" color="on-primary" bold>
          {primaryLabel}
        </RMText>
      </PrimaryButton>
      <RMTooltip message={secondaryTooltip} side="top">
        <SecondaryButton onClick={onSecondaryClick}>
          <SecondaryIcon icon={secondaryIcon} />
        </SecondaryButton>
      </RMTooltip>
    </SplitButtonContainer>
  );
}
