import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Extensions } from '@tiptap/core';
import { Document } from '@tiptap/extension-document';
import { History } from '@tiptap/extension-history';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Text } from '@tiptap/extension-text';
import { EditorProvider, JSONContent, useCurrentEditor } from '@tiptap/react';

interface EditorControllerMethods {
  setEditable: (editable: boolean) => void;
  setValue: (value: JSONContent | null | undefined) => void;
}

export function stringToRMTextEditorContent(str: string | undefined | null) {
  return {
    type: 'doc',
    content:
      str?.split('\n').map((splitStr) => ({
        type: 'paragraph',
        content:
          splitStr.length > 0
            ? [
                {
                  type: 'text',
                  text: splitStr,
                },
              ]
            : undefined,
      })) ?? [],
  };
}

const EditorController = forwardRef<EditorControllerMethods>(function (props, ref) {
  const { editor } = useCurrentEditor();

  useImperativeHandle(
    ref,
    () => ({
      setEditable: (editable: boolean) => editor?.setEditable(editable),
      setValue: (value: JSONContent | null | undefined) => {
        const anchor = editor?.state.selection.anchor;
        editor?.commands.setContent(value ?? null);
        // Setting the content resets the anchor position. This keeps it in the place it should be.
        editor?.commands.focus(anchor);
      },
    }),
    [editor],
  );

  return null;
});

export interface RMTextEditorProps {
  editable?: boolean;
  value?: JSONContent | null;
  onChange?: (newValue: JSONContent) => void;
  onBlur?: () => void;
  className?: string;
  extensions?: Extensions;
}

// The TipTap editor is not reactive, this makes some properties reactive
export function RMTextEditor(props: RMTextEditorProps) {
  const editorControllerRef = useRef<EditorControllerMethods>(null);
  const currentValue = useRef<JSONContent>();

  useEffect(() => {
    if (props.editable == null) {
      return;
    }

    editorControllerRef.current?.setEditable(props.editable);
  }, [props.editable]);

  useEffect(() => {
    if (currentValue.current === props.value) {
      return;
    }

    editorControllerRef.current?.setValue(props.value);
  }, [props.value]);

  // TipTap does not update the onUpdate callback in every render.
  // So we need to add the state we need to access in the callback inside a ref.
  const propsRef = useRef(props);
  propsRef.current = props;

  return (
    <EditorProvider
      slotAfter={<EditorController ref={editorControllerRef} />}
      editable={props.editable}
      onUpdate={(event) => {
        if (!propsRef.current.editable || !event.transaction.docChanged) {
          return;
        }

        const changedValue = event.editor.getJSON();
        currentValue.current = changedValue;
        propsRef.current.onChange?.(changedValue);
      }}
      extensions={[Document, Paragraph, Text, History, ...(props.extensions ?? [])]}
      content={props.value}
      editorProps={{
        attributes:
          props.className != null
            ? {
                class: props.className,
              }
            : undefined,
      }}
      onBlur={() => propsRef.current.onBlur?.()}
    />
  );
}
