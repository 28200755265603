import { useEffect, useState } from 'react';
import { faCog } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMInput } from '@/components/RMInput/RMInput';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { Footer, inputStyles, ShareLinkButton, ShareLinkInput, ShareLinkRow } from './StoryShareDialog.styles';

interface StoryShareDialogProps {
  open: boolean;
  shareLink: string;
  onCopyShareLink: () => void;
  onRegenerateShareLink: () => Promise<void>;
  onClose: () => void;
}

export function StoryShareDialog({
  open,
  shareLink,
  onCopyShareLink,
  onRegenerateShareLink,
  onClose,
}: StoryShareDialogProps) {
  const [step, setStep] = useState<'share' | 'regenerate'>('share');
  const isMobile = useIsMobileViewport();

  const handleRegenerateShareLink = async () => {
    await onRegenerateShareLink();
    setStep('share');
  };

  // Reset share state
  useEffect(() => {
    if (!open) {
      setStep('share');
    }
  }, [open]);

  return (
    <RMDialog.Root open={open} onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title={step === 'share' ? 'Share this story via link' : 'Regenerate share link'}
          message={
            step === 'share'
              ? 'Anyone can use this link to view this story. They will not be able to view other stories.'
              : 'Create a new link to share this story. Doing so will delete all previous links, which cannot be undone.'
          }
          rightAdornment={<RMCloseButton onClick={onClose} />}
        />
        <RMDialog.Footer>
          {step === 'share' && (
            <ShareLinkRow>
              <ShareLinkInput>
                <RMInput id="link" value={shareLink} className={inputStyles} readOnly />
                <ShareLinkButton
                  icon={faCog}
                  tooltip={{ label: 'Link settings', position: 'top' }}
                  backgroundColor="transparent"
                  color="primary"
                  onClick={() => setStep('regenerate')}
                />
              </ShareLinkInput>
              <RMButton onClick={onCopyShareLink} background="primary">
                Copy
              </RMButton>
            </ShareLinkRow>
          )}

          {step === 'regenerate' && (
            <Footer>
              <RMButton fullWidth={isMobile} onClick={() => setStep('share')} background="neutral">
                Cancel
              </RMButton>
              <RMButton fullWidth={isMobile} autoLoading background="primary" onClick={handleRegenerateShareLink}>
                Regenerate
              </RMButton>
            </Footer>
          )}
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
