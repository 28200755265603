import { styled } from '@linaria/react';

import { RMDialog } from '@/components/RMDialog';

export const LearnMoreDialogDialogContent = styled(RMDialog.Content)`
  max-width: 35.5rem !important;
`;

export const LearnMoreDialogDialogHeader = styled(RMDialog.Header)`
  padding-bottom: 0 !important;
`;

export const LearnMoreDialogDialogBody = styled.div`
  display: flex;
  gap: var(--spacing-xl);
  opacity: 1;
  flex: 1;

  padding: 0 var(--spacing-xl) var(--spacing-md) var(--spacing-xl);
`;

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  flex: 1;
`;

export const Image = styled.img`
  height: 238px;
`;
