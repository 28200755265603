import { styled } from '@linaria/react';

import { LottieAnimation } from '@/components/LottieAnimation';

export const EmojiList = styled.div`
  display: flex;
  gap: var(--spacing-sm);
`;
export const Emoji = styled.div`
  width: 3rem;
  height: 3rem;
  font-size: 1.75rem;
  cursor: pointer;
  border-radius: var(--radius-full);
  display: flex;
  justify-content: center;
  align-items: center;

  // background-color: var(--surface-dim);
  border: 1px solid var(--border-hairline);

  :hover {
    background-color: var(--darken-neutral-hover);
  }

  &[data-selected='true'] {
    background-color: var(--primary);
  }

  &[data-inactive='true'] {
    opacity: 0.4;
  }

  &[data-disabled='true'] {
    pointer-events: none;
    opacity: 0.4;
  }
`;

export const LottieWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13.25rem;
`;

export const LottieSpinner = styled(LottieAnimation)`
  position: relative;
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;
`;

export const LottieCheckmark = styled(LottieAnimation)`
  position: relative;
  width: 5rem;
  height: 5rem;
  flex: 0 0 5rem;
`;

export const SubmittedContent = styled.div`
  height: 26rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md);
`;
