import { ComponentProps } from 'react';

import { InputBar, InputInnerBar, InputRange, Wrapper } from './RMInputRange.styles';

interface RMInputRangeProps extends ComponentProps<'input'> {
  min: number;
  max: number;
  value: number;
}

export function RMInputRange({ min, max, value, ...props }: RMInputRangeProps) {
  const maxValue = max - min;
  const offSetValue = value - min;
  const innerBarWidth = value ? `${(offSetValue / maxValue) * 100}%` : '0%';

  return (
    <Wrapper>
      <InputBar>
        <InputInnerBar style={{ width: innerBarWidth }} />
        <InputRange type="range" onChange={props.onChange} min={min} max={max} value={value} {...props} />
      </InputBar>
    </Wrapper>
  );
}
