import { useRef } from 'react';

import { Form } from '@/modules/form/@types';
import { Paths, PathValueType } from '@/modules/form/@types-utils';
import { InputController } from '@/modules/form/container';

import { RMTextEditorStore } from './RMTextEditor.state';
import { RMTextEditorController, RMTextEditorControllerProps } from './RMTextEditorController';

export type RMTextEditorInputControllerProps<
  In extends object,
  Out extends object = In,
  P extends Paths<In> = Paths<In>,
> = Omit<RMTextEditorControllerProps, 'onChange'> & {
  store: RMTextEditorStore;
  form: Form<In, Out>;
  path: P;
};

export function RMTextEditorInputController<
  In extends object,
  Out extends object = In,
  P extends Paths<In> = Paths<In>,
>({ store, form, path, ...props }: RMTextEditorInputControllerProps<In, Out, P>) {
  const currentRef = useRef(0);

  return (
    <InputController form={form} path={path}>
      {({ onChange, ...inputProps }) => (
        <RMTextEditorController
          store={store}
          {...inputProps}
          {...props}
          onChange={() => {
            onChange(`${++currentRef.current}` as PathValueType<In, P>);
          }}
        />
      )}
    </InputController>
  );
}
