import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-left: auto;

  border-radius: var(--radius-regular);
  border: 1px solid var(--inverse-border-weak);
  background: var(--color-spruce-10);
  overflow: hidden;

  &[data-theme='light'] {
    border-color: var(--border-hairline);
    background: var(--surface);
  }
`;

export const ReactionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  cursor: pointer;

  :hover {
    background: var(--lighten-white-hover);
  }

  :active {
    background: var(--lighten-white-press);
  }

  &[data-theme='light'] {
    :hover {
      background: var(--darken-neutral-hover);
    }

    :active {
      background: var(--darken-neutral-press);
    }
  }
`;
