import { styled } from '@linaria/react';

export const IssuesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
`;

export const IssuesListItem = styled.div`
  display: flex;
  flex-direction: column;
`;
