import { StoryDataType, StoryLengthType, StoryPerspectiveType } from '@remento/types/story';
import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export function createStorySummaryFormSchema() {
  return z.object({
    title: z.string(),
    summary: z.string(),
    perspective: z.nativeEnum(StoryPerspectiveType),
    length: z.nativeEnum(StoryLengthType),
    type: z.nativeEnum(StoryDataType),
  });
}

export type StorySummaryFormInput = z.input<ReturnType<typeof createStorySummaryFormSchema>>;
export type StorySummaryFormOutput = z.output<ReturnType<typeof createStorySummaryFormSchema>>;

export type StorySummaryForm = Form<StorySummaryFormInput, StorySummaryFormOutput>;

export function createStorySummaryForm(defaultValues?: DeepPartial<StorySummaryFormInput>): StorySummaryForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createStorySummaryFormSchema(),
  });
}
