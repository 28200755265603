import { useMemo } from 'react';
import {
  AssetAlternative,
  AssetAlternativeMetadataType,
  BaseAssetAlternativeType,
  ThumbnailType,
} from '@remento/types/alternative';
import { AssetType } from '@remento/types/asset';
import { EntityType } from '@remento/types/entity';
import { notNull } from '@remento/utils/array/notNull';
import { useQueries } from '@tanstack/react-query';

import { useServices } from '@/Services';
import {
  useAlternativeFileUrl,
  useAlternativeType,
  useAlternativeVideoUrl,
  useAssetAlternativesQuery,
  useAssetQuery,
} from '@/services/api/asset';
import { usePersonAvatarUrl, usePersonInitials, usePersonQuery } from '@/services/api/person';
import { useRecordingQuery } from '@/services/api/recording';
import { useStoryQuery } from '@/services/api/story';
import { areAllQueriesLoaded } from '@/utils/query';

export function useStoryVideoAlternative(storyId: string | null): AssetAlternative | null | undefined {
  const storyQuery = useStoryQuery(storyId);
  const recordingAlternativesQuery = useAssetAlternativesQuery(storyQuery.data?.recordingsIds[0]);
  return useAlternativeType(
    recordingAlternativesQuery.data,
    BaseAssetAlternativeType.ORIGINAL,
    AssetAlternativeMetadataType.VIDEO,
  );
}

export function useStoryVideoUrl(storyId: string | null): string | null {
  const recordingAlternative = useStoryVideoAlternative(storyId);
  return useAlternativeVideoUrl(recordingAlternative?.id);
}

export function useStoryThumbnailAlternative(
  storyId: string | null,
  format: ThumbnailType,
): AssetAlternative | null | undefined {
  const { entityCacheManagerService, assetService } = useServices();

  const storyQuery = useStoryQuery(storyId);
  const alternativesIdsQuery = useAssetAlternativesQuery(storyQuery.data?.recordingsIds[0]);

  const alternativesQuery = useQueries(
    useMemo(
      () => ({
        queries: (alternativesIdsQuery.data ?? []).map((alternativeId) =>
          entityCacheManagerService.buildEntityQuery(EntityType.ASSET_ALTERNATIVE, alternativeId, (_, scope) => {
            return assetService.getAlternative(alternativeId, scope);
          }),
        ),
      }),
      [alternativesIdsQuery.data, assetService, entityCacheManagerService],
    ),
  );

  return useMemo(() => {
    if (areAllQueriesLoaded(alternativesQuery) === false) {
      return undefined;
    }

    const alternatives = alternativesQuery.map((q) => q.data).filter(notNull);
    const thumbnailAlternative = alternatives.find((alternative) => {
      if (alternative.metadata?.type != AssetAlternativeMetadataType.IMAGE_THUMBNAIL) {
        return false;
      }
      return alternative.metadata.thumbnailFormat === format;
    });
    return thumbnailAlternative ?? null;
  }, [alternativesQuery, format]);
}

export function useStoryThumbnailUrl(storyId: string | null, format: ThumbnailType): string | null {
  const thumbnailAlternative = useStoryThumbnailAlternative(storyId, format);
  return useAlternativeFileUrl(thumbnailAlternative?.id);
}

export function useStoryVideoType(storyId: string | null): 'audio' | 'video' | null {
  const storyQuery = useStoryQuery(storyId);
  const recordingAssetQuery = useAssetQuery(storyQuery.data?.recordingsIds[0]);

  if (recordingAssetQuery.data) {
    return recordingAssetQuery.data.type === AssetType.AUDIO_RECORDING ? 'audio' : 'video';
  }

  return null;
}

export interface StoryVideoAuthor {
  avatarUrl: string | null;
  initials: string;
}

export function useStoryVideoAuthor(storyId: string | null): StoryVideoAuthor {
  const storyQuery = useStoryQuery(storyId);
  const recordingQuery = useRecordingQuery(storyQuery.data?.recordingsIds[0]);
  const authorPersonQuery = usePersonQuery(recordingQuery.data?.personId);
  const avatarUrl = usePersonAvatarUrl(authorPersonQuery.data);
  const initials = usePersonInitials(authorPersonQuery.data);

  return {
    avatarUrl,
    initials,
  };
}
