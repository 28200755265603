import { styled } from '@linaria/react';

import { Player } from '@/modules/media-player/Player';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16rem;
  height: 16rem;

  &[data-box='true'] {
    width: 100%;
    height: 100%;
    background-color: var(--color-spruce-15);
    border: 2px solid var(--inverse-border-hairline);
    border-radius: var(--radius-smooth);
    box-shadow: 0px 0px 0px 0px rgba(14, 23, 24, 0.38), 0px 6px 14px 0px rgba(14, 23, 24, 0.37),
      4px 26px 28px 0px rgba(14, 23, 24, 0.32), 8px 60px 36px 0px rgba(14, 23, 24, 0.19),
      16px 108px 44px 0px rgba(14, 23, 24, 0.06), 24px 168px 48px 0px rgba(14, 23, 24, 0.01);
  }
`;

export const Audio = styled(Player)`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
`;

export const AvatarContainer = styled.div`
  width: 100%;
  border-radius: var(--radius-full);
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  cursor: pointer;

  &[data-box='true'] {
    width: 6rem;

    body[data-mobile='true'] & {
      width: 3.6rem;
    }

    body[data-tablet='true'] & {
      width: 5rem;
    }

    body[data-fullscreen='true'] & {
      width: 12rem;
    }

    body[data-tablet='true'][data-fullscreen='true'] &,
    body[data-mobile='true'][data-fullscreen='true'] & {
      width: 6rem;
    }
  }
`;

export const AvatarImage = styled.img`
  border-radius: var(--radius-full);
  background-color: var(--primary);
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const AvatarInitials = styled.span`
  font-family: var(--font-sans);
  color: var(--on-primary);
  font-size: 80px;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;

  body[data-tablet='true'] &[data-size='large'],
  body[data-mobile='true'] &[data-size='large'] {
    font-size: 33px;
  }

  &[data-size='small'] {
    font-size: 16px;
  }

  &[data-size='medium'] {
    font-size: 20px;
  }

  body[data-mobile='false'][data-tablet='false'][data-fullscreen='true'] &[data-size='medium'] {
    font-size: 45px;
  }
`;
