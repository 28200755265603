import { useCallback } from 'react';
import { useMediaRemote } from '@vidstack/react';

import { isFullScreen } from '@/hooks/useIsFullscreen';
import { isIpad, isIphone } from '@/utils/isMobile';

import { isForcedFullScreen, setForcedFullScreen, useFullscreenStore } from '../states';

export function useEnterFullscreen(): () => void {
  const fullscreenStore = useFullscreenStore();
  const remote = useMediaRemote();

  return useCallback(() => {
    if (isFullScreen(fullscreenStore)) {
      return;
    }

    if (isIphone() || isIpad()) {
      setForcedFullScreen(fullscreenStore, true);
      return;
    }

    remote.enterFullscreen();
  }, [fullscreenStore, remote]);
}

export function useExitFullscreen(): () => void {
  const fullscreenStore = useFullscreenStore();
  const remote = useMediaRemote();

  return useCallback(() => {
    if (isFullScreen(fullscreenStore) === false) {
      return;
    }

    if (isForcedFullScreen(fullscreenStore)) {
      setForcedFullScreen(fullscreenStore, false);
      return;
    }

    remote.exitFullscreen();
  }, [fullscreenStore, remote]);
}

export function useToggleFullscreen(): () => void {
  const fullscreenStore = useFullscreenStore();

  const enterFullscreen = useEnterFullscreen();
  const exitFullscreen = useExitFullscreen();

  return useCallback(() => {
    if (isFullScreen(fullscreenStore)) {
      exitFullscreen();
      return;
    }

    enterFullscreen();
  }, [enterFullscreen, exitFullscreen, fullscreenStore]);
}
