import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { MediaRemoteControl } from '@vidstack/react';

export class VideoManager {
  private remotes = new Set<MediaRemoteControl>();

  pauseOthers(currentRemote: MediaRemoteControl) {
    this.remotes.forEach((remote) => {
      if (currentRemote !== remote) {
        remote.pause();
      }
    });
  }

  registerRemote(remote: MediaRemoteControl): () => void {
    this.remotes.add(remote);

    return () => this.remotes.delete(remote);
  }
}

export const VideoManagerContext = createContext<VideoManager | null>(null);

export function VideoManagerProvider({ children }: PropsWithChildren) {
  const videoManager = useMemo(() => new VideoManager(), []);

  return <VideoManagerContext.Provider value={videoManager}>{children}</VideoManagerContext.Provider>;
}

export function useVideoManager(remote: MediaRemoteControl) {
  const videoManager = useContext(VideoManagerContext);
  useEffect(() => videoManager?.registerRemote(remote), [remote, videoManager]);

  return videoManager;
}
