import { BaseEntity, BaseEntityStatus, BaseMutation } from './base-entity';

export enum ReactionSentiment {
  LOVE = 'LOVE',
  FUNNY = 'FUNNY',
  SURPRISING = 'SURPRISING',
  TOUCHING = 'TOUCHING',
}

export const ReactionSentimentEmoji: Record<ReactionSentiment, string> = {
  LOVE: '❤️',
  FUNNY: '😂',
  SURPRISING: '😯',
  TOUCHING: '🥹',
};

export enum ReactionStatus {
  SUBMITTED_AUTO = 'SUBMITTED_AUTO',
  SUBMITTED = 'SUBMITTED',
  PENDING = 'PENDING',
  DELAYED = 'DELAYED',
}

interface BaseReaction extends BaseEntity<ReactionStatus> {
  recordingId: string;
  personId: string;
  acl: Array<string>; // Inherited from the recording
}

export interface PendingReaction extends BaseReaction {
  status: ReactionStatus.PENDING | BaseEntityStatus.DELETED;
}

export interface DelayedReaction extends BaseReaction {
  status: ReactionStatus.DELAYED | BaseEntityStatus.DELETED;
  sentiment: ReactionSentiment;
}

export interface SubmittedReaction extends BaseReaction {
  status: ReactionStatus.SUBMITTED | BaseEntityStatus.DELETED;
  sentiment?: ReactionSentiment | null;
  suggestionId?: string | null;
  message: string;
}

export interface AutoSubmittedReaction extends BaseReaction {
  status: ReactionStatus.SUBMITTED_AUTO | BaseEntityStatus.DELETED;
  sentiment: ReactionSentiment;
}

export type Reaction = PendingReaction | DelayedReaction | SubmittedReaction | AutoSubmittedReaction;

export enum ReactionMutationType {
  SET_DELAYED = 'set-delayed',
  SET_SUBMITTED = 'set-submitted',
  SET_SUBMITTED_AUTO = 'set-submitted-auto',
}

export type DelayReactionStatusMutation = BaseMutation<
  ReactionMutationType.SET_DELAYED,
  { sentiment: ReactionSentiment }
>;

export type SubmittedReactionStatusMutation = BaseMutation<
  ReactionMutationType.SET_SUBMITTED,
  { sentiment?: ReactionSentiment | null; message: string; suggestionId?: string | null }
>;

export type AutoSubmittedReactionStatusMutation = BaseMutation<
  ReactionMutationType.SET_SUBMITTED_AUTO,
  { sentiment: ReactionSentiment }
>;

export type ReactionMutation =
  | DelayReactionStatusMutation
  | SubmittedReactionStatusMutation
  | AutoSubmittedReactionStatusMutation;

export interface ReactionSuggestion extends BaseEntity {
  id: string;
  recordingId: string;
  sentiment: ReactionSentiment;
  message: string;
  acl: Array<string>; // Inherited from the recording
}

export enum ReactionSuggestionMutationType {
  SET_STATUS = 'set-status',
}

export type SetReactionSuggestionStatusMutation = BaseMutation<
  ReactionSuggestionMutationType.SET_STATUS,
  BaseEntityStatus
>;

export type ReactionSuggestionMutation = SetReactionSuggestionStatusMutation;

export interface GeneratedReactionSuggestion {
  id: string;
  recordingId: string;
  sentiment: ReactionSentiment;
  message: string;
  acl: Array<string>; // Inherited from the recording
}
