import { StoriesManager, useActiveStoryId, useShouldLoadStory } from '../states/stories.manager';
import { StoryState } from '../states/story.manager';

import { StoryViewContainer } from './StoryView.container';

interface StoryPlaceholderContainerProps {
  storiesManager: StoriesManager;
  storyId: string;
  animationDir: 'ltr' | 'rtl' | null;
  onFormDirtyChange: (isDirty: boolean) => void;
  onChangeStoryState: (state: StoryState) => void;
  onClose: () => void;
}

export function StoryPlaceholderContainer({
  storiesManager,
  storyId,
  animationDir,
  onFormDirtyChange,
  onChangeStoryState,
  onClose,
}: StoryPlaceholderContainerProps) {
  const activeStoryId = useActiveStoryId(storiesManager);
  const shouldLoad = useShouldLoadStory(storiesManager, storyId);

  if (!shouldLoad) {
    return null;
  }

  return (
    <StoryViewContainer
      storyId={storyId}
      storiesManager={storiesManager}
      activeStory={activeStoryId === storyId}
      animationDir={animationDir}
      onFormDirtyChange={onFormDirtyChange}
      onChangeStoryState={onChangeStoryState}
      onClose={onClose}
    />
  );
}
