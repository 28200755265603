import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { TimeSlider } from '@vidstack/react';

import { RMText } from '@/components/RMText/RMText';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column;
  pointer-events: auto;
`;

export const Controls = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ControlsSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Times = styled(RMText)`
  min-width: 6rem;
  margin-left: var(--spacing-xs);
  white-space: nowrap;

  body[data-mobile='true'] & {
    margin-left: 0;
  }
`;

export const TimeSliderRoot = styled(TimeSlider.Root)`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 40px;
  position: relative;
  contain: layout style;
  outline: none;
  pointer-events: auto;
  cursor: pointer;
  user-select: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  [data-focus] .track {
    box-shadow: var(--media-focus-ring);
  }

  [data-dragging] > .thumb {
    box-shadow: 0 0 0 3px hsla(0, 0%, 100%, 0.4);
  }

  body[data-mobile='true'] & {
    height: 24px;
  }
`;

export const TimeSliderTrackFill = styled(TimeSlider.TrackFill)`
  z-index: 2; /** above track and track progress. */
  background-color: var(--inverse-on-surface-primary);
  width: var(--slider-fill, 0%);
  height: 100%;
  will-change: width;
`;

export const TimeSliderProgress = styled(TimeSlider.Progress)`
  width: var(--chapter-progress, 0%);
  will-change: width;
  background-color: rgb(255 255 255 / 0.5);
`;

export const TimeSliderThumb = styled(TimeSlider.Thumb)`
  position: absolute;
  top: 50%;
  left: var(--slider-fill);
  opacity: 1;
  contain: layout size style;
  width: 15px;
  height: 15px;
  border: 1px solid #cacaca;
  border-radius: var(--radius-full);
  background-color: #fff;
  transform: translate(-50%, -50%) translateZ(0);
  transition: opacity 0.15s ease-in;
  pointer-events: none;
  will-change: left;
  z-index: 2; /** above track fill. */
`;

export const timeSliderTrackStyles = css`
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 5px;
  top: 50%;
  left: 0;
  border-radius: var(--radius-minimal);
  transform: translateY(-50%) translateZ(0);
  contain: strict;

  body[data-mobile='true'] & {
    height: 3px;
  }
`;

export const DotSeparator = styled(RMText)`
  margin-left: var(--spacing-xs);
  transform: scale(0.5);
`;

export const PromptQuestionText = styled(RMText)`
  margin-left: var(--spacing-xs);
  white-space: nowrap;
  display: block;
  flex-grow: 1;
  max-width: calc(100% - 5rem);
`;
