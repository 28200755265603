import { useCallback } from 'react';
import { JSONContent } from '@tiptap/react';
import { createStore, useStore } from 'zustand';

export interface RMTextEditorState {
  value: JSONContent | null | undefined;
}

export type RMTextEditorStore = ReturnType<typeof createRMTextEditorStore>;

export function createRMTextEditorStore(value?: JSONContent) {
  return createStore<RMTextEditorState>(() => ({ value }));
}

export function setRMTextEditorValue(store: RMTextEditorStore, value: JSONContent | null | undefined): void {
  store.setState({
    value,
  });
}

export function getRMTextEditorStateText(state: RMTextEditorState): string {
  return (state.value?.content ?? []).map((paragraph) => paragraph.content?.[0].text).join('\n');
}

export function getRMTextEditorText(store: RMTextEditorStore): string {
  return getRMTextEditorStateText(store.getState());
}

export function useRMTextEditorValue(store: RMTextEditorStore) {
  return useStore(
    store,
    useCallback(({ value }) => value, []),
  );
}
