import { RMTextEditor, RMTextEditorProps } from './RMTextEditor';
import { RMTextEditorStore, setRMTextEditorValue, useRMTextEditorValue } from './RMTextEditor.state';

export type RMTextEditorControllerProps = Omit<RMTextEditorProps, 'value'> & {
  store: RMTextEditorStore;
};

export function RMTextEditorController({ store, ...props }: RMTextEditorControllerProps) {
  const value = useRMTextEditorValue(store);

  return (
    <RMTextEditor
      {...props}
      value={value}
      onChange={(newValue) => {
        setRMTextEditorValue(store, newValue);
        props.onChange?.(newValue);
      }}
    />
  );
}
