import { styled } from '@linaria/react';

export const Content = styled.div`
  height: 100%;

  top: 0;
  position: absolute;
  &[data-visible='true'] {
    position: unset;
  }

  pointer-events: none;
  &[data-interactable='true'] {
    pointer-events: unset;
  }
`;

export const HighlightsReelButton = styled.div`
  cursor: pointer;
  display: flex;
  gap: var(--spacing-xs);
`;
