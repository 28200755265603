import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

export const SplitButtonContainer = styled.div`
  display: flex;
  gap: 1px;
`;

export const PrimaryButton = styled.button`
  position: relative;
  height: 48px;
  min-width: fit-content;
  padding: 0 var(--spacing-md);
  box-shadow: var(--elevation-bottom-2);
  border-radius: var(--radius-regular) 0 0 var(--radius-regular);
  background-color: var(--primary);

  &:after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: var(--radius-regular) 0 0 var(--radius-regular);
  }

  :hover {
    &:after {
      background-color: var(--lighten-primary-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--lighten-primary-press);
    }
  }
`;

export const SecondaryButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  background-color: var(--primary);
  box-shadow: var(--elevation-bottom-2);
  border-radius: 0 var(--radius-regular) var(--radius-regular) 0;

  &:after {
    position: absolute;
    content: ' ';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: var(--radius-regular) 0 0 var(--radius-regular);
  }

  :hover {
    &:after {
      background-color: var(--lighten-primary-hover);
    }
  }

  :active {
    &:after {
      background-color: var(--lighten-primary-press);
    }
  }
`;

export const SecondaryIcon = styled(FontAwesomeIcon)`
  width: 24px;
  color: var(--on-primary);
`;
