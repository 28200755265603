import { css } from '@linaria/core';

export const mediaPlayerStyles = css`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 100%;
  width: 100%;
  cursor: default !important;
`;
