import { Audio, AvatarContainer, AvatarImage, AvatarInitials, Container } from './StoryAudioPlayer.styles';

export interface StoryAudioPlayerProps {
  authorAvatarUrl?: string | null;
  authorInitials: string;
  size: 'small' | 'medium' | 'large';
  box?: boolean;
}

export function StoryAudioPlayer({ authorAvatarUrl, authorInitials, size, box }: StoryAudioPlayerProps) {
  return (
    <Container data-box={box}>
      <Audio />
      <AvatarContainer data-box={box}>
        {authorAvatarUrl ? (
          <AvatarImage src={authorAvatarUrl} alt="author photo" />
        ) : authorInitials !== 'N/A' ? (
          <AvatarInitials data-size={size}>{authorInitials}</AvatarInitials>
        ) : null}
      </AvatarContainer>
    </Container>
  );
}
