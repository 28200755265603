import { faFacebook, faWhatsapp, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faArrowDownToLine, faEllipsis, faEnvelope, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMText } from '@/components/RMText/RMText';

import {
  DefaultSocialButton,
  FacebookButton,
  inputStyles,
  ShareLinkInput,
  ShareLinkRow,
  SocialButtons,
  SocialButtonWrapper,
  WhatsappButton,
  XTwitterButton,
} from './SocialShareDialog.styles';

interface StoryReactionDialogProps {
  open: boolean;
  shareLink: string;
  onShareAction: (source: 'email' | 'facebook' | 'twitter' | 'whatsapp' | 'download') => Promise<void>;
  onCopyShareLink: () => void;
  onClose: () => void;
}

export function SocialShareDialog({
  open,
  shareLink,
  onShareAction,
  onCopyShareLink,
  onClose,
}: StoryReactionDialogProps) {
  return (
    <RMDialog.Root open={open} onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header title="Share" rightAdornment={<RMCloseButton onClick={onClose} />} />

        <RMDialog.Body>
          <SocialButtons>
            <SocialButtonWrapper>
              <FacebookButton onClick={() => onShareAction('facebook')}>
                <FontAwesomeIcon icon={faFacebook} size="xl" />
              </FacebookButton>
              <RMText size="xxs" type="sans" bold color="on-surface-primary">
                Facebook
              </RMText>
            </SocialButtonWrapper>
            <SocialButtonWrapper>
              <DefaultSocialButton onClick={() => onShareAction('download')}>
                <FontAwesomeIcon icon={faArrowDownToLine} size="xl" />
              </DefaultSocialButton>
              <RMText size="xxs" type="sans" bold color="on-surface-primary">
                Download
              </RMText>
            </SocialButtonWrapper>
            <SocialButtonWrapper>
              <DefaultSocialButton onClick={() => onShareAction('email')}>
                <FontAwesomeIcon icon={faEnvelope} size="xl" />
              </DefaultSocialButton>
              <RMText size="xxs" type="sans" bold color="on-surface-primary">
                Email
              </RMText>
            </SocialButtonWrapper>
            <SocialButtonWrapper>
              <WhatsappButton onClick={() => onShareAction('whatsapp')}>
                <FontAwesomeIcon icon={faWhatsapp} size="xl" />
              </WhatsappButton>
              <RMText size="xxs" type="sans" bold color="on-surface-primary">
                Whatsapp
              </RMText>
            </SocialButtonWrapper>
            <SocialButtonWrapper>
              <XTwitterButton onClick={() => onShareAction('twitter')}>
                <FontAwesomeIcon icon={faXTwitter} size="xl" />
              </XTwitterButton>
              <RMText size="xxs" type="sans" bold color="on-surface-primary">
                X
              </RMText>
            </SocialButtonWrapper>
          </SocialButtons>
        </RMDialog.Body>

        <RMDialog.Footer>
          <ShareLinkRow>
            <ShareLinkInput>
              <RMInput id="link" value={shareLink} className={inputStyles} readOnly />
            </ShareLinkInput>
            <RMButton onClick={onCopyShareLink} background="primary">
              Copy
            </RMButton>
          </ShareLinkRow>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
